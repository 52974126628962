import React, {useEffect, useState} from 'react';
import Routes from "./router";
// @ts-ignore
import thinkingdata from "thinkingdata-browser";
import zhCN from "antd-mobile/es/locales/zh-CN";
import enUS from "antd-mobile/es/locales/en-US";
import zhTW from "antd-mobile/es/locales/zh-TW";
import idID from "antd-mobile/es/locales/id-ID";
import {ConfigProvider} from "antd-mobile";



function App() {
    const [locale,setLocale]  = useState(idID)
    useEffect(() => {
        const config = {
            appId: '49109fee70e64b12851d62f766e76408',
            serverUrl: 'https://global-receiver-ta.thinkingdata.cn',
            autoTrack: {
                pageShow: true, //开启页面展示事件，事件名ta_page_show
                pageHide: true, //开启页面隐藏事件，事件名ta_page_hide
            }
        };
        thinkingdata.init(config);
        // 将 SDK 实例赋给全局变量 ta，或者其他您指定的变量
        // @ts-ignore
        window.ta = thinkingdata;
        // @ts-ignore
        window.ta.init(config);
        // @ts-ignore
        // @ts-ignore
        // window.ta.track('withdraw');

    },[])
    const Language = localStorage.getItem('language')
   useEffect(() => {
       if (Language === '3') {
           setLocale(zhCN)
       }else if (Language === '1') {
           setLocale(enUS)
       }else if (Language === '4') {
           setLocale(zhTW)
       }else if (Language === '0') {
           setLocale(idID)
       }else {
           setLocale(enUS)
       }
   },[Language])
  return (
      <div >
          <ConfigProvider locale={locale} >
              <Routes/>
          </ConfigProvider>
      </div>

  );
}

export default App;
