import React from 'react';
import {useRoutes,RouteObject,Navigate} from "react-router-dom";
import Balance from "../views/balance/Balance";
import Withdrawal from "../views/withdrawal/Withdrawal";
import GiftDetails from "../views/giftDetails/GiftDetails";
import Index from "../views/index/Index";
import WithdrawalRecords from "../views/withdrawal/WithdrawalRecords";
import WithdrawalStatus from "../views/withdrawal/WithdrawalStatus";
import AddWithdrawMethod from "../views/addWithdrawMethod/AddWithdrawMethod";
import Web3Wallet from "../views/web3Wallet/Web3Wallet";
import Feedback from "../views/feedback";
import FbHistory from "../views/feedback/history";
import FbDetail from "../views/feedback/detail";
import UploadMv from "../views/uploadMv";
import Invite from "../views/invite";
import AddCollectionAccount from "../views/addCollectionAccount";
import FollowMe from "../views/follow_me";
const Routes = () => {

    const routers:RouteObject[] = [

        {
            path: '/',
            element: <Index/>,
            children: [
                // {
                //     path:'/',
                //     element:<Navigate to={'/balance'}/>,
                // },

                {
                    path: '/balance',
                    element: <Balance/>,
                },
                {
                    path: '/withdrawal',
                    element: <Withdrawal/>
                },
                {
                    path: '/giftDetails',
                    element: <GiftDetails/>
                },
                {
                    path:"/withdrawalRecords",
                    element:<WithdrawalRecords/>
                },
                {
                    path:'/withdrawalStatus/:orderId',
                    element:<WithdrawalStatus/>
                },
                {
                    path:'/addWithdrawMethod',
                    element:<AddWithdrawMethod/>
                },
                {
                    path:'/web3Wallet',
                    element:<Web3Wallet/>
                },
                {
                    path:'/feedback',
                    element:<Feedback/>
                },
                {
                    path:'/fb_history',
                    element:<FbHistory/>
                },
                {
                    path:'/fb_detail/:id',
                    element:<FbDetail/>
                },
                {
                    path:'/upload_mv',
                    element:<UploadMv/>
                },
                {
                    path:'/invite',
                    element:<Invite/>
                },
                {
                    path:'/add_collection_account',
                    element:<AddCollectionAccount/>
                },
                {
                    path:'/follow_me',
                    element:<FollowMe/>,
                }
            ]
        }
    ]

    const element = useRoutes(routers)

    return (
        element
    );
};

export default Routes;

