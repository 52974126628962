import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import cn from './cn.json'
import tc from './tc.json'
import id from './id.json'

const lng = 'en';
i18n.use(initReactI18next)
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en,
            },
            zh: {
                translation: cn,
            },
            tc: {
                translation: tc,
            },
            id: {
                translation: id,
            }
        },
        lng: lng,
        fallbackLng: lng,
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n
