import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import UploadMvBg from "../../assets/images/uploadmv/upload_mv_bg.jpg"
import {useWindowSize} from "../../useWindowSize";
import FlexBox from "../../commen/FlexBox";
import Uploader from "../../commen/Uploader";
import RemoveIcon from "../../assets/images/uploadmv/remove_icon.png"
import {Toast} from "antd-mobile";
import {getToken} from "../../utils/token";
import {getUploadMvUrl, updateMvStatus} from "../../services/feedback";
import md5 from "js-md5";
import axios from "axios";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  background-color: #FFFFFF;
`
const Bg = styled.div`
  background-image: url(${UploadMvBg});
  background-size: cover;
  width: 23.5rem;
  height: 11.541rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`
const Logo = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  object-fit: cover;
`
const Title = styled.div`
  font-size: 1rem;
  color: #43474E;
  margin-top: 1rem;
`
const Tips = styled.div`
  font-size: 0.75rem;
  color: #8E929F;
  margin-top: 0.75rem;
`
const Btn = styled.div`
  width: 17.25rem;
  height: 2.315rem;
  background: ${props => props.bgcolor};
  border-radius: 0.625rem;
  font-size: 0.94rem;
  color: ${props => props.color};
  text-align: center;
  line-height: 2.315rem;
`
const FileBox = styled.div`
  width: 17.25rem;
  height: 1.69rem;
  background: ${props => props.bgcolor};
  border-radius: 0.85rem;
  font-size: 0.875rem;
  border: 0.13px solid #E4E4E4;
  color: #43474E;
  text-align: center;
  line-height: 1.69rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`
const DeleteIcon = styled.img`
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.25rem;
`
const Input = styled.input`
  width: 12rem;
  height: 1.5rem;
  background-color: #FFFFFF;
  outline: none;
  border: none;
  color: #43474E;
  font-size: 0.875rem;
  margin-left: 0.5rem;

`
const Success = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
`
const Text = styled.div`
  font-size: 0.875rem;
  color: #8E929F;
  margin-top: 0.75rem;
  max-width: 17.5rem;
`
const FileName = styled.div`
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 0.25rem;
  max-width:17.25rem
`
const UploadMv = () => {
    const {height} = useWindowSize()
    const [mvFile, setMvFile] = useState(null)
    const [songName, setSongName] = useState('')
    const [singerName, setSingerName] = useState('')
    const [mvTime, setMvTime] = useState(0)
    const [isUpload,setIsUpload] = useState(true)
    const RoleId = localStorage.getItem('roleId')
    const Token = getToken()
    const Name = localStorage.getItem('Name')
    const Language = localStorage.getItem('language')
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        if (Language === '3') {
            i18n.changeLanguage('zh')
        }else if (Language === '1') {
            i18n.changeLanguage('en')
        }else if (Language === '4') {
            i18n.changeLanguage('tc')
        }else if (Language === '0') {
            i18n.changeLanguage('id')
        }else {
            i18n.changeLanguage('en')
        }
    },[Language])

    const putMv = async () => {
        if (songName === ''){
            return  Toast.show({icon:'fail',content:t('Song name cannot be empty')})
        }
        if (singerName === '') {
            return  Toast.show({icon:'fail',content:t('Singer name cannot be empty')})
        }
        const time = new Date().getTime()
        const sign = RoleId + Token + time
        Toast.show({icon:'loading',content:t('Uploading'),duration:0})
        const response = await getUploadMvUrl({
            RoleId: RoleId,
            FileName: mvFile.name,
            Song: songName,
            Artist: singerName,
            Token: Token,
            TimeStamp: time,
            Sign: md5(sign),
            TimeSecond: Math.round(mvTime),
            RoleName:Name
        })
        if (response.data.code === 1003 || response.data.code === 1001 || response.data.code !== 0) {
            return  Toast.show({icon:'fail',content:t('The link has expired, please try entering again')})
        }
        return new Promise( async (resolve, reject) => {
            if(response.data.code === 0){
                const headers = new Headers();
                headers.append('Content-Type', 'multipart/form-data'); // 设置文件的 Content-Type
                axios.put(response.data.data, mvFile,{
                    headers: headers,
                }).then(async (res) => {
                    if (res.status === 200) {
                        const url = response.data.msg
                        const r =  await updateMvStatus({RoleId: RoleId,
                            VideoUrl:url,
                            Status:1,
                            Token: Token,
                            TimeStamp: time,
                            Sign: md5(sign),})
                        if (r.data.code === 0){
                            setMvTime(0)
                            setMvFile(null)
                            setSingerName('')
                            setSongName('')
                            setIsUpload(false)
                            Toast.show({icon:'success',content:t('Upload Successfully')})

                        }else {
                            Toast.show({icon:'fail',content:t('Upload failed')})
                        }
                        resolve({url:url,state:200})
                    }else {
                        reject({ url: '',state:403})
                        Toast.show({icon:'fail',content:t('Upload failed')})
                    }

                }).catch(err => {
                    reject({ url: '' +err,state:403})
                    Toast.show({icon:'fail',content:t('Upload failed')})
                })


            }else {
                reject({ url: '',state:403})
                Toast.show({icon:'fail',content:t('Upload failed')})
            }
        })
    }
    return (
        <Container style={{height: height,}}>
            <Bg>
                <div style={{marginTop: "2rem", width: "17.5rem"}}>
                    <Logo src={require('../../assets/images/uploadmv/logo_icon.png')}/>
                    <Title>partyYoo</Title>
                    {isUpload &&  <Tips>{t('Note')}：{t('only mp4 files are supported')}</Tips>}
                </div>


            </Bg>
            {!isUpload && <FlexBox style={{flexDirection: "column"}}>
                <Success src={require('../../assets/images/uploadmv/success_icon.png')}/>
                <Title>{t('only mp4 files are supported')}</Title>
                <Text>{t('Please be patient and wait for the staff to review within 1-3 working days You can check it later on the (My Upload) page when singing mode is enable Look, order a song.')}</Text>
                <Btn onClick={() => window.location.href = "uniwebview://close"} style={{marginTop: "1rem"}} bgcolor={"#5847F5"} color={'#FFFFFF'}>{t('Back')}</Btn>
                <Btn onClick={() => {setIsUpload(true)}} style={{marginTop: "1rem"}} bgcolor={"#F2F2F2"} color={'#5847F5'}>{t('Continue')}</Btn>

            </FlexBox>}
            {isUpload &&
            <FlexBox style={{flexDirection: "column"}}>
                {mvFile && <FileBox>
                    <FileName>{mvFile.name}</FileName>
                    <DeleteIcon src={RemoveIcon} onClick={() => {
                        setMvFile(null)
                    }}/></FileBox>}
                {mvFile && <FileBox><Input type={'text'} maxLength={30} value={songName} placeholder={t('Please enter a song name')} onChange={(e) => {
                    setSongName(e.target.value)
                }}/>
                    {songName.length > 0 && <DeleteIcon src={RemoveIcon} onClick={() => {
                        setSongName('')
                    }}/>}

                </FileBox>}
                {mvFile && <FileBox><Input type={'text'} maxLength={10} value={singerName} placeholder={t("Please enter the singer's name")} onChange={(e) => {
                    setSingerName(e.target.value)
                }}/>
                    {singerName.length > 0 && <DeleteIcon src={RemoveIcon} onClick={() => {
                        setSingerName('')
                    }}/>}

                </FileBox>}
                <Uploader accept={'video/*'} id={'mv_upload'} style={{marginTop: "2rem"}} onSuccess={(file) => {
                    if (!file.type.includes('video/mp4')) {
                        return Toast.show({
                            icon: 'fail',
                            content:t('Format error, please upload a video file in MP4 format'),
                        })
                    }
                    const video = document.createElement('video');
                    video.preload = 'metadata';
                    video.onloadedmetadata = () => {
                        setMvTime(video.duration)
                    };
                    video.src = URL.createObjectURL(file);
                    setMvFile(file)
                }}>
                    <Btn style={{marginTop: "1rem"}} bgcolor={mvFile ? "#F2F2F2" : "#5847F5"}
                         color={mvFile ? "#5847F5" : '#FFFFFF'}>{mvFile ?t("Choose Music Video" ):t("Choose Music")}</Btn>
                </Uploader>
                {mvFile &&
                <Btn onClick={putMv} style={{marginTop: "1rem"}} bgcolor={"#5847F5"} color={'#FFFFFF'}>{t('Confirm')}</Btn>}

            </FlexBox>

            }

        </Container>
    );
};

export default UploadMv
