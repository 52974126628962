const tokenKey = 'to_address'

export const setToAddress= (token:string) => {
    localStorage.setItem(tokenKey, token)
}

export const getToAddress= () => {
    const token = localStorage.getItem(tokenKey)
    return token ? token : ''
}

export const clearToAddress = () => {
    localStorage.removeItem(tokenKey)
}
