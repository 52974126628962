import axios from 'axios'
import {getToken} from "./token";
import {BaseUrl} from "./baseUrl";
const baseURL = BaseUrl + ':5003'
//http://192.168.33.6:5003
const request = axios.create({
    baseURL:baseURL,//基准地址
    timeout:5000,
})
//拦截请求
request.interceptors.request.use((config)=>{
    config.headers['Origin'] = BaseUrl +':5003'
    return config
})
//拦截响应
request.interceptors.response.use((response)=>{
        return response
    },function (error){
        //对响应的错误做点什么
        return Promise.reject(error);
    }
)

export default request
