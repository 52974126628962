import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavBarRight, useWallets} from "../../hooks";
import FlexBox from "../../commen/FlexBox";
import BIcon from "../../assets/images/balance/tx_icon_yoo_coin.png";
import {DatePicker, Toast} from 'antd-mobile'
import {useNavigate} from "react-router-dom";
import {payClient} from "../../services/client";
import MetaMask from "../../assets/images/web3Wallet/tx_icon_wallet_metamask.png";
import Paypal from "../../assets/images/balance/tx_icon_wallet_paypal.png";
import {copyText, formatTime} from "../../utils";
import {useWindowSize} from "../../useWindowSize";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
`
const CIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const DownIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const GiftItem = styled.div`
  height: 3.375rem;
  width: 21.5rem;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 10.125rem;
`
const ItemIcon = styled.img`
  width: 2.75rem;
  height: 2.75rem;
`
const ItemTime = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`
const SIcon = styled.img`
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.25rem;
`
const WBox = styled.div`
    background: #FFFFFF;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
`
const NoContent = styled.div`
  font-size:0.625rem;
  color: #99999C;
`
const RecordItem = styled.div`
  height: 3.375rem;
  width: 21.5rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const Status = styled.div`
  font-size: 0.625rem;
  color: #292930;
  margin-top: 0.875rem;
  text-align: right;
`
const CopyIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const WithdrawalRecords = () => {
    const {setHasRight} = useNavBarRight()
    const [visible,setVisible] = useState(false)
    const [list,setList] = useState([])
    const month = new Date().getMonth()+1
    const time = new Date().getFullYear().toString() +'-'+ (month<10?`0${month}`:month).toString()
    const [date,setDate] = useState(time)
    const navigate = useNavigate()
    const {height} =useWindowSize()
    const {wallets} = useWallets()
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        setHasRight(false)
        const timeNum:number = new Date(date).getTime() + 86400000*2
        payClient.callApi('open/HisTory_time',{address:wallets[0].publicAddress,time:timeNum}).then((res:any) => {
            if (res.isSucc) {
                setList(res.res.list)
            }
        })
    },[date])
    function getStatus(state:number) {
        if (state === 0){
            return t('In review')
        }else if (state === 1){
            return t('Failed')
        }else if (state === 2){
            return t('Withdrawing')
        }else if (state === 3){
            return t('Success')
        }
    }
    return (
        <Container style={{minHeight:height}}>
            <FlexBox onClick={() => setVisible(true)} style={{justifyContent:"flex-start",width:"20.625rem",marginTop:"1rem"}}>
                <CIcon src={require('../../assets/images/giftDetails/tx_icon_date.png')}/>
                <div style={{marginLeft:"0.5rem"}}>{date}</div>
                <DownIcon src={require('../../assets/images/giftDetails/tx_icon_extend_arrow_shu.png')}/>
            </FlexBox>
            <DatePicker
                visible={visible}
                onClose={() => {setVisible(false)}}
                precision='month'
                confirmText={'confirm'}
                cancelText={'cancel'}
                onConfirm={val => {
                    let year = val.getFullYear()
                    let month = val.getMonth()
                    let d = `${year}-${(month+1)<10?(`0${month+1}`):month+1}`
                    setDate(d)
                }
                }
            />
            <WBox>
                {list.map((item:any,index:number) => {
                    return (
                        <RecordItem key={index} onClick={(e:any) => {navigate('/withdrawalStatus/'+item.OrderID);e.stopPropagation()}}>
                            <FlexBox >
                                <ItemIcon src={item.type==='paypal'?Paypal:MetaMask}/>
                                <div style={{marginLeft:"0.7rem"}}>
                                    <FlexBox>
                                        <ItemText>{item.address}</ItemText>
                                        <CopyIcon onClick={(e:any) => {copyText(item.address);e.stopPropagation()}} src={require('../../assets/images/balance/tx_btn_copy_2.png')}/>
                                    </FlexBox>
                                    <ItemTime style={{marginTop:"0.7rem"}}>{formatTime(item.createTime)}</ItemTime>
                                </div>
                            </FlexBox>
                            <div>
                                <FlexBox style={{justifyContent:item.type === '1'?"center":"flex-end",flexDirection:item.type === '1'?"column":"row",alignItems:item.type === '1'?"flex-end":"center"}}>
                                    <ItemText>- {item.money}</ItemText>
                                    {item.type === '1' ?<ItemText>{'USDT'}</ItemText>: <SIcon src={BIcon}/>}
                                </FlexBox>
                                <Status>{getStatus(item.state)}</Status>
                            </div>
                        </RecordItem>
                    )
                })}
            </WBox>
            {list.length === 0 && <NoContent  style={{marginTop:height/2-80}}>No withdrawal records for this month</NoContent>}


        </Container>
    );
};

export default WithdrawalRecords;
