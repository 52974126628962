import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useLanguage, useNavBarRight} from "../../hooks";
import FlexBox from "../../commen/FlexBox";
import BIcon from "../../assets/images/balance/tx_icon_yoo_coin.png";
import {DatePicker, Toast, InfiniteScroll} from 'antd-mobile'
import {formatTime} from "../../utils";
import {getGiftsList} from "../../services/giftList";
import {getGiftImage} from "../../utils/getGiftsImg";
import {useWindowSize} from "../../useWindowSize";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  
`
const CIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const DownIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const GiftItem = styled.div`
  height: 3.375rem;
  width: 21.5rem;
  border-bottom: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;

  &:hover {
    filter: opacity(0.8);
  }
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 13.125rem;
`
const ItemIcon = styled.img`
  width: 2.75rem;
  height: 2.75rem;
`
const ItemTime = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`
const SIcon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`
const WBox = styled.div`
  background: #FFFFFF;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
`
const NoContent = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`

interface GiftTypes {
    CreateTime: number
    GiftValue: number
    GiftValueSrc: number
    Id: number
    Remark: string
    RoleID: number
    SendRoleName: string
    ItemId: number
    ItemCount: number,
    ItemName: string
}

const GiftDetails = () => {
    const {setHasRight} = useNavBarRight()
    const [visible, setVisible] = useState(false)
    const [giftList, setGiftList] = useState<GiftTypes []>([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const month = new Date().getMonth() + 1
    const time = new Date().getFullYear().toString() + '-' + (month < 10 ? `0${month}` : month).toString()
    const [date, setDate] = useState(time)
    const {height} = useWindowSize()
    const {language} = useLanguage()
    const {state: {roleId}} = useLocation()
    const { t ,i18n} = useTranslation();
    async function loadMore() {
        if (page < total / 5) {
            let nexPage = page + 1
            setPage(nexPage)
        }
        await gitList()
    }

    function gitList() {
        const timeNum = new Date(date).getTime() + 86400000
        getGiftsList({
            RoleId: roleId,
            TimeStamp: timeNum,
            PageNumber: page,
            PageSize: 5,
            Language: language
        }).then(res => {
            if (res.data.code === 0) {
                let resData = JSON.parse(res.data.data)
                setTotal(JSON.parse(res.data.msg).Count)
                if (page === 0) {
                    setGiftList(resData)
                } else {
                    setGiftList(value => [...value, ...resData])
                }

            }
        }).catch(err => {

        })
    }

    useEffect(() => {
        setHasRight(false)
        gitList()
    }, [])
    useEffect(() => {
        setPage(0)
        const timeNum = new Date(date).getTime() + 86400000
        getGiftsList({
            RoleId: roleId,
            TimeStamp: timeNum,
            PageNumber: page,
            PageSize: 5,
            Language: language
        }).then(res => {
            if (res.data.code === 0) {
                let resData = JSON.parse(res.data.data)
                setGiftList(resData)
                setTotal(JSON.parse(res.data.msg).Count)
            }
        }).catch(err => {

        })
    }, [date])
    return (
        <Container style={{minHeight:height}}>
            <FlexBox onClick={() => setVisible(true)}
                     style={{justifyContent: "flex-start", width: "20.625rem", marginTop: "1rem"}}>
                <CIcon src={require('../../assets/images/giftDetails/tx_icon_date.png')}/>
                <div style={{marginLeft: "0.5rem"}}>{date}</div>
                <DownIcon src={require('../../assets/images/giftDetails/tx_icon_extend_arrow_shu.png')}/>
            </FlexBox>
            <DatePicker
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                precision='month'
                confirmText={t('Confirm')}
                cancelText={t('Cancel')}
                onConfirm={val => {
                    let year = val.getFullYear()
                    let month = val.getMonth()
                    let d = `${year}-${(month + 1) < 10 ? (`0${month + 1}`) : month + 1}`
                    setPage(0)
                    setDate(d)
                }
                }
            />
            <WBox>
                {giftList?.map((item, index) => {

                    return (
                        <GiftItem key={index}>
                            <FlexBox>
                                <ItemIcon src={getGiftImage(item.ItemId)}/>
                                <div style={{marginLeft: "0.7rem"}}>
                                    <ItemText>{`${item.SendRoleName} give ${item.ItemName} x${item.ItemCount}`}</ItemText>
                                    <ItemTime style={{marginTop: "0.7rem"}}>{formatTime(item.CreateTime)}</ItemTime>
                                </div>
                            </FlexBox>
                            <FlexBox>
                                <ItemText>{item.GiftValue / 100}</ItemText>
                                <SIcon src={BIcon}/>
                            </FlexBox>
                        </GiftItem>
                    )
                })}
            </WBox>
            {giftList.length === 0 &&
            <NoContent style={{marginTop: height / 2 - 80}}>No gift records for this month</NoContent>}
            {giftList.length !== 0 &&
            <InfiniteScroll loadMore={loadMore} threshold={10} hasMore={giftList.length < total}/>}
        </Container>
    );
};

export default GiftDetails;
