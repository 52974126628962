import { ServiceProto } from 'tsrpc-proto';
import { ReqList, ResList } from './admin/PtlList';
import { ReqLogin, ResLogin } from './admin/PtlLogin';
import { ReqNoPassTask, ResNoPassTask } from './admin/PtlNoPassTask';
import { ReqPassTask, ResPassTask } from './admin/PtlPassTask';
import { ReqAddWdTask, ResAddWdTask } from './open/PtlAddWdTask';
import { ReqHisTory_time, ResHisTory_time } from './open/PtlHisTory_time';
import { ReqHisTory, ResHisTory } from './open/PtlHisTory';
import { ReqQueryOrder_id, ResQueryOrder_id } from './open/PtlQueryOrder_id';
import { ReqCreateTask, ResCreateTask } from './open/w2w_log/PtlCreateTask';
import { ReqFaillTask, ResFaillTask } from './open/w2w_log/PtlFaillTask';
import { ReqPassTask as ReqPassTask_1, ResPassTask as ResPassTask_1 } from './open/w2w_log/PtlPassTask';

export interface ServiceType {
    api: {
        "admin/List": {
            req: ReqList,
            res: ResList
        },
        "admin/Login": {
            req: ReqLogin,
            res: ResLogin
        },
        "admin/NoPassTask": {
            req: ReqNoPassTask,
            res: ResNoPassTask
        },
        "admin/PassTask": {
            req: ReqPassTask,
            res: ResPassTask
        },
        "open/AddWdTask": {
            req: ReqAddWdTask,
            res: ResAddWdTask
        },
        "open/HisTory_time": {
            req: ReqHisTory_time,
            res: ResHisTory_time
        },
        "open/HisTory": {
            req: ReqHisTory,
            res: ResHisTory
        },
        "open/QueryOrder_id": {
            req: ReqQueryOrder_id,
            res: ResQueryOrder_id
        },
        "open/w2w_log/CreateTask": {
            req: ReqCreateTask,
            res: ResCreateTask
        },
        "open/w2w_log/FaillTask": {
            req: ReqFaillTask,
            res: ResFaillTask
        },
        "open/w2w_log/PassTask": {
            req: ReqPassTask_1,
            res: ResPassTask_1
        }
    },
    msg: {

    }
}

export const serviceProto: ServiceProto<ServiceType> = {
    "version": 23,
    "services": [
        {
            "id": 0,
            "name": "admin/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 3,
            "name": "admin/Login",
            "type": "api",
            "conf": {}
        },
        {
            "id": 4,
            "name": "admin/NoPassTask",
            "type": "api",
            "conf": {}
        },
        {
            "id": 5,
            "name": "admin/PassTask",
            "type": "api",
            "conf": {}
        },
        {
            "id": 2,
            "name": "open/AddWdTask",
            "type": "api",
            "conf": {}
        },
        {
            "id": 7,
            "name": "open/HisTory_time",
            "type": "api",
            "conf": {}
        },
        {
            "id": 6,
            "name": "open/HisTory",
            "type": "api",
            "conf": {}
        },
        {
            "id": 8,
            "name": "open/QueryOrder_id",
            "type": "api",
            "conf": {}
        },
        {
            "id": 9,
            "name": "open/w2w_log/CreateTask",
            "type": "api",
            "conf": {}
        },
        {
            "id": 10,
            "name": "open/w2w_log/FaillTask",
            "type": "api",
            "conf": {}
        },
        {
            "id": 11,
            "name": "open/w2w_log/PassTask",
            "type": "api",
            "conf": {}
        }
    ],
    "types": {
        "admin/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "../../protocols/base/BaseRequest": {
            "type": "Interface",
            "properties": [
                {
                    "id": 1,
                    "name": "AuthHash",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 0,
                    "name": "__token",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "admin/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "admin/PtlList/__wd_task"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "users",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "admin/PtlList/wd_log"
                        }
                    }
                }
            ]
        },
        "../../protocols/base/BaseResponse": {
            "type": "Interface"
        },
        "admin/PtlList/__wd_task": {
            "type": "Interface",
            "properties": [
                {
                    "id": 12,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 14,
                    "name": "user_id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "financial_id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "OrderID",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "state",
                    "type": {
                        "type": "Reference",
                        "target": "admin/PtlList/wd_state"
                    }
                },
                {
                    "id": 2,
                    "name": "createTime",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "open/PtlAddWdTask/ExtType"
                    }
                },
                {
                    "id": 9,
                    "name": "commission",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "money",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 10,
                    "name": "actualReceipt",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 6,
                    "name": "freeze_game_money",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 11,
                    "name": "endTime",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 7,
                    "name": "cb",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 13,
                    "name": "RoleId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "admin/PtlList/wd_state": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": 0
                },
                {
                    "id": 1,
                    "value": 1
                },
                {
                    "id": 2,
                    "value": 2
                },
                {
                    "id": 3,
                    "value": 3
                }
            ]
        },
        "open/PtlAddWdTask/ExtType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "0"
                },
                {
                    "id": 1,
                    "value": "1"
                },
                {
                    "id": 2,
                    "value": "paypal"
                },
                {
                    "id": 3,
                    "value": "first_dw"
                },
                {
                    "id": 4,
                    "value": "payermax_bank"
                },
                {
                    "id": 5,
                    "value": "payermax_wallet"
                }
            ]
        },
        "admin/PtlList/wd_log": {
            "type": "Interface",
            "properties": [
                {
                    "id": 5,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "createTime",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "lasgWd_log",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "all_wd_cont",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "all_wdGame_cont",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "admin/PtlLogin/ReqLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "acc",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "psw",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "admin/PtlLogin/ResLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "_token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "mail",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "admin/PtlNoPassTask/ReqNoPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "admin/PtlNoPassTask/ResNoPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "admin/PtlPassTask/ReqPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "callBack",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "actualReceipt",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "commission",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "admin/PtlPassTask/ResPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "open/PtlAddWdTask/ReqAddWdTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 5,
                    "name": "OrderID",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "RoleId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 10,
                    "name": "RoleName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "Wall_address",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "ExtType",
                    "type": {
                        "type": "Reference",
                        "target": "open/PtlAddWdTask/ExtType"
                    }
                },
                {
                    "id": 7,
                    "name": "Wd_money",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "Freeze_game_money",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 11,
                    "name": "Financial_Id",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "open/PtlAddWdTask/ResAddWdTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "open/PtlHisTory_time/ReqHisTory_time": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "time",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "open/PtlHisTory_time/ResHisTory_time": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "admin/PtlList/__wd_task"
                        }
                    }
                }
            ]
        },
        "open/PtlHisTory/ReqHisTory": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "page",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "limit",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "open/PtlHisTory/ResHisTory": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "admin/PtlList/__wd_task"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "maxLength",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "open/PtlQueryOrder_id/ReqQueryOrder_id": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "OrderID",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "open/PtlQueryOrder_id/ResQueryOrder_id": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "item",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Reference",
                                    "target": "admin/PtlList/__wd_task"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "open/w2w_log/PtlCreateTask/ReqCreateTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "OrderID",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "RoleId",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "Wall_address",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "Wd_money",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "open/w2w_log/PtlCreateTask/ResCreateTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "open/w2w_log/PtlFaillTask/ReqFaillTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "open/w2w_log/PtlFaillTask/ResFaillTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "open/w2w_log/PtlPassTask/ReqPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "callBack",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "actualReceipt",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "commission",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "open/w2w_log/PtlPassTask/ResPassTask": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        }
    }
};