/*时间戳转换*/
import {Toast} from "antd-mobile";

export function formatTime(formatTime:number) {
    const date = new Date(formatTime);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}

/*时间格式转换*/
export function format(formatTime:string) {
    const a = new Date(formatTime).getTime();
    const date = new Date(a);
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}

/*时间格式转换月日*/
export function formatMonthDay(formatTime:string) {
    const a = new Date(formatTime).getTime();
    const date = new Date(a);
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
    const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    return M + D + h + m ;
}

export function insertStr (str:string, index:number, insertStr:string) {
    const ary = str.split('');		// 转化为数组
    ary.splice(index, 0, insertStr);	// 使用数组方法插入字符串
    return ary.join('');				// 拼接成字符串后输出
}
export function isEmail(email:string) {
    const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
    return regEmail.test(email);
}

export function copyText(text:string) {
    try {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        Toast.show({icon:'success',content:'Copy success'},)
    } catch (err){
        Toast.show({icon:'fail',content:'Copy failed'},)
    }

}

export function isMultipleOfTen(num:number) {
    if (num <= 0) {
        return false; // 如果数字不是正整数，直接返回false
    }
    return num % 10 === 0; // 判断num是否是10的倍数
}
