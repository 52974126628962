import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import { ConfigProvider, ErrorBlock } from 'antd-mobile'
import './language/index'
import zhCN from 'antd-mobile/es/locales/zh-CN'
import zhTW from 'antd-mobile/es/locales/zh-TW'
import enUS from 'antd-mobile/es/locales/en-US'
import idID from "antd-mobile/es/locales/id-ID";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
      <BrowserRouter>
          <App />


      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
