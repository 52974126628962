import React from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useModal} from "../../../hooks";
import {useTranslation} from "react-i18next";


const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 21.43rem;
  height: 23.47rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem 1.125rem ;
`
const Title = styled.div`
  font-size: 1rem;
  color: #292930;
  font-weight: bold;
`
const ItemBox = styled.div`
  width: 20.5rem;
  height: 5.25rem;
  background: #FFFFFF;
  border: 0.13px solid #EEEEEE;
  border-radius: 0.5rem;
  padding:0.5rem 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`
const ItemText = styled.div`
  font-size: 1rem;
  color: #99999C;
  margin-top: 1rem;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`
const WithdrawWayModal = ({balance}) => {
    const navigate = useNavigate()
    const {setOpenModal} = useModal()
    const { t ,i18n} = useTranslation();
    return (
        <Container>
            <Title>{t('Select Withdrawal Method')}</Title>
            <ItemBox onClick={() => {navigate('/withdrawal',{state:{balance:balance,wType:0}});setOpenModal(false)}}>
                <div>
                    <Title>{t('Bank Transfer')}</Title>
                    <ItemText>{t('Supports more than 100 mainstream banks, and the arrival time may be affected by bank business hours')}</ItemText>
                </div>
                <RightArrowIcon src={require('../../../assets/images/balance/tx_icon_list_arrow.png')}/>
            </ItemBox>
            <ItemBox onClick={() => {navigate('/withdrawal',{state:{balance:balance,wType:1}});setOpenModal(false)}}>
                <div>
                    <Title>{t('E-wallet')}</Title>
                    <ItemText>{t('Supports GOPAY, OVO, SHOPEEPAY, DANA, etc.')}</ItemText>
                </div>
                <RightArrowIcon src={require('../../../assets/images/balance/tx_icon_list_arrow.png')}/>
            </ItemBox>
            {/*<ItemBox onClick={() => {navigate('/web3Wallet');setOpenModal(false)}}>*/}
            {/*    <div>*/}
            {/*        <Title>{t('WEB3 transfer')}</Title>*/}
            {/*        <ItemText>{t('Transfer to the in-app WEB3 wallet and withdraw it to any external wallet that supports the Polygon chain.')}</ItemText>*/}
            {/*    </div>*/}
            {/*    <RightArrowIcon src={require('../../../assets/images/balance/tx_icon_list_arrow.png')}/>*/}
            {/*</ItemBox>*/}
        </Container>
    );
};

export default WithdrawWayModal;
