import Head_001 from '../assets/images/avatar/n_gr_icon_head_default_001.png';
import Head_002 from '../assets/images/avatar/n_gr_icon_head_default_002.png';
import Head_101 from '../assets/images/avatar/n_gr_icon_head_default_101.png';
import Head_102 from '../assets/images/avatar/n_gr_icon_head_default_boy_102.png';
import Head_103 from '../assets/images/avatar/n_gr_icon_head_default_boy_103.png'
import Head_104 from '../assets/images/avatar/n_gr_icon_head_default_boy_104.png';
import Head_201 from '../assets/images/avatar/n_gr_icon_head_default_girl_201.png';
import Head_202 from '../assets/images/avatar/n_gr_icon_head_default_202.png';
import Head_203 from '../assets/images/avatar/n_gr_icon_head_default_girl_203.png';
import Head_204 from '../assets/images/avatar/n_gr_icon_head_default_girl_204.png';

export const getAvatarImg = (id) => {
    switch (id) {
        case '001':
            return Head_001
        case '002':
            return Head_002
        case '101':
            return Head_101
        case '102':
            return Head_102
        case '103':
            return Head_103
        case '104':
            return Head_104
        case '201':
            return Head_201
        case '202':
            return Head_202
        case '203':
            return Head_203
        case '204':
            return Head_204


    }
}
