import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CountDown from "../../commen/CountDown";
import FlexBox from "../../commen/FlexBox";
import {isEmail} from "../../utils";
import SuccessIcon from "../../assets/images/withdrawal/tx_order_state_success.png";
import {useNavBarRight, useUserInfo} from "../../hooks";
import {mailClient} from "../../services/client";
import {Toast} from "antd-mobile";

const Container = styled.div`
  margin: 0.625rem;
  
`
const WBox = styled.div`
  width: 20.125rem;
  height: 2.0625rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.652rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.875rem;
`
const Title = styled.div`
  font-size: 0.625rem;
  color: #232323;
  margin-top: 1.25rem;
  margin-left: 0.25rem;
`
const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  margin-top:1rem;
`
const Input = styled.input`
  width: 10rem;
  height: 2rem;
  outline: none;
  border: none;
  color: #232323;
  font-size: 1rem;
  
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #FFFFFF;
  line-height: 2.75rem;
  text-align: center;
  position: fixed;
  bottom: 1.5rem;
  left:4.075rem
`
const ErrText = styled.div`
  font-size: 0.625rem;
  color: #E74848;
  margin-left: 1rem;
  margin-top: 0.25rem;
`
const StatusIcon = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 2rem;
`
const AddWithdrawMethod = () => {
    const {userInfo} = useUserInfo()
    const [startTimer,setStartTimer] = useState(false)
    const [address, setAddress] = useState(userInfo.paypal_acc?userInfo.paypal_acc:'')
    const [code,setCode] = useState('')
    const [errAddress,setErrAddress] = useState(false)
    const [submitStatus,setSubmitStatus] = useState(false)
    const {setHasRight} = useNavBarRight()
    const {setUserInfo} = useUserInfo()
    useEffect(() => {
        setHasRight(false)
    },[])
    function getCode() {
        if (address === ''){
            Toast.show({icon:'fail',content:'Please enter your PayPal address'})
            return;
        }
        if (!isEmail(address)){
            setErrAddress(true);
            return;
        }
        mailClient.callApi('GetCheckCode',{acc:address}).then(res => {
            if (res.isSucc) {
                setStartTimer(true)
                Toast.show({icon:'success',content:'Verification code sent'})
            }
        }).catch(err => {

        })

    }
    const bindPayPal = () => {
        mailClient.callApi('bind_acc/BindPaypal',{bind:address}).then(res => {
            if (res.isSucc){
                setSubmitStatus(true)
                mailClient.callApi('Login',{AuthHash:'24e89787-d3e7-4477-8a46-6c25c75a488a'}).then(res => {
                    if (res.isSucc){

                        setUserInfo(res.res.info)
                    }

                }).catch(err => {

                })

            }
        }).catch(err => {

        })
    }
    function submit() {
        if (code === ''){
            Toast.show({icon:'fail',content:'Please enter your verification code'})
        }
        mailClient.callApi('CheckCode',{acc:address,code:code}).then(res => {
            if (res.isSucc){
                bindPayPal()
            }else {
                Toast.show({icon:'fail',content:'Check sdk code failed'})
            }
        })

    }
    return (
        <div>
            {!submitStatus &&   <Container>
                <Title>PayPal account email</Title>
                <WBox>
                    <Input value={address} onChange={(e:any) => setAddress(e.target.value)} type={'text'} placeholder={'Email address'} />
                </WBox>
                {errAddress && <ErrText>Email address is wrong</ErrText>}

                <Title>Captcha</Title>
                <WBox>
                    <Input value={code} onChange={(e:any) => setCode(e.target.value)} type={'text'} placeholder={'Email verification code'} />
                    <CountDown style={{
                        width:"4rem",height:"2rem",borderRadius:"0.375rem",textAlign:'center',lineHeight:"2rem"
                    }} onClick={getCode} status={startTimer}/>
                </WBox>
                {/*<ErrText>Captcha is wrong</ErrText>*/}
                <Btn color={(address !== '' &&code.length>3)?"#5C46FF":"#E2E5E9"} onClick={submit}>Confirm</Btn>
            </Container>}
            {submitStatus && <FlexBox style={{marginTop:"17.625rem",flexDirection:"column"}}>
                <StatusIcon src={SuccessIcon}/>
                <div style={{marginTop:"1.375rem"}}>Binding succeeded</div>
            </FlexBox>}
        </div>
    );
};

export default AddWithdrawMethod;
