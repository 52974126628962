import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../commen/FlexBox";
import {useNavigate} from "react-router-dom";
import {useInitWallet, useIsBack, useMaticRate, useWallets} from "../../hooks";
import {copyText} from "../../utils";
import wallet from "../../utils/wallet";
import { utils } from 'aoas-web-sdk'
import {Toast} from "antd-mobile";
import {setFromAddress} from "../../utils/fromAddress";
import {setToAddress} from "../../utils/toAddress";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  margin: 0.625rem;
  
`

const Title = styled.div`
  font-size: 0.875rem;
  color: #232323;
`

const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 18.125rem;
`
const CopyIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`

const SText = styled.div`
  font-size: 0.575rem;
  color: #292930;
`
const SIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`
const ItemBox = styled.div`
  display: flex;
  width: 20.875rem;
  align-items: center;
  justify-content:space-between;
  background: #FFFFFF;
  height:4.0625rem;
  border-bottom:1px solid #EEEEEE;
  padding: 0 0.625rem;
  border-radius: 0.625rem;
`
const WBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 1rem;
`
const ItemTitle = styled.div`
  font-size: 0.5625rem;
  color: #292930;
`

const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: #5C46FF;
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  position: fixed;
  bottom: 2rem;
  left: 4.075rem;
  &:hover {
    filter: opacity(0.8);
  }
`
interface WalletTypes  {
    chainType:string,
    publicAddress:string
}
const Web3Wallet = () => {
    const [balance,setBalance] = useState({})
    const navigate = useNavigate()
    const {wallets} = useWallets()
    // const [matic,setMatic] = useState()
    const [list,setList] = useState([])
    const [decimals,setDecimals] = useState([])
    const {initWallet} = useInitWallet()
    const {setMaticRate} = useMaticRate()
    const { t ,i18n} = useTranslation();
   async function getBan() {
       wallet.getBalance().then((res:any) => {
           setBalance(res)
           Toast.show({icon:'success',content:t('Obtained wallet balance'),})
       }).catch(err => {})
       wallet.getTokenList().then((res:any) => {
           console.log(res)
           setList(res)
           let decimalsArr:any[] = []
           res.forEach((item:any) => decimalsArr.push(item.decimals))
           // @ts-ignore
           setDecimals(decimalsArr)
           let ma:any = res.find((item:any) => item.name === 'MATIC')
           wallet.getRate(ma.address).then((res:any) => {
               if (res.data){
                   setMaticRate(res.data[0])
               }
           }).catch(err => {})
       }).catch(err => {})

    }



    useEffect(() => {
        try {
                getBan().catch(err => {})
        } catch (err) {

        }

    },[])
    const defaultAddress = localStorage.getItem('defaultAddress')
    const hrAddress = localStorage.getItem('hrAddress')
    useEffect(() => {
        // @ts-ignore
        if (wallets){
            const hr = wallets[0].publicAddress
            setFromAddress(hr)
        }
        if (hrAddress){
            const hr = JSON.parse(hrAddress)[0]
            setFromAddress(hr.publicAddress)
        }
        if (defaultAddress){
            setToAddress(defaultAddress)
        }

    },[])
    function getUs() {
        let us = list.find((item:any) => item.name === 'USDT')
        let i = list.findIndex((item:any) => item.name === 'USDT')
        if (!us){
            return 0
        }else {
            if (Object.values(balance).length>0){
                const value = Object.values(balance)[i]

                // @ts-ignore
                return  utils.bigDivided(value,decimals[i])
            }else {
                return 0
            }
        }
    }

    function  getMatic() {
        // let ma = list.find((item:any) => item.name === 'MATIC')
        // let i = list.findIndex((item:any) => item.name === 'MATIC')
        // if (!ma){
        //     return 0
        // }else {
        //     if (Object.values(balance).length>0){
        //         const value = Object.values(balance)[i]
        //         // @ts-ignore
        //         return  utils.bigDivided(value,decimals[i])
        //     }
        // }
    }


    return (
        <Container>
            <Title>{t('Wallet Address')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                <ItemText>{wallets[0].publicAddress?wallets[0].publicAddress:hrAddress}</ItemText>
                <CopyIcon onClick={() => {copyText(wallets[0].publicAddress)}} src={require('../../assets/images/withdrawal/tx_btn_copy_1.png')}/>
            </ItemBox>
            <Title style={{marginTop:"1.5rem"}}>{t('My Balance')}</Title>
            <WBox >
                <ItemBox >
                    {/*<ItemTitle>{getUs()}</ItemTitle>*/}
                    <SIcon src={require('../../assets/images/web3Wallet/tx_icon_coin_USDT.png')}/>
                </ItemBox>
                <ItemBox>
                    {/*<ItemTitle>{getMatic()}</ItemTitle>*/}
                    <SIcon src={require('../../assets/images/web3Wallet/tx_icon_coin_MATIC.png')}/>
                </ItemBox>
            </WBox>
            <Btn onClick={() => {
                if (getUs() < 5){
                    Toast.show({icon:'fail',content:t('Your USDT is less than 5 and cannot be withdrawn')})
                    return;
                }

                navigate('/withdrawal?type=web3',{state:{usdt:0}})
            }
            }>{t('Withdraw')}</Btn>
        </Container>
    );
};

export default Web3Wallet;
