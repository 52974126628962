import React, {useEffect} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useMaticRate, useNavBarRight, useUserInfo, useWallets} from "../../../hooks";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;
import BigNumber from "bignumber.js";
import {useTranslation} from "react-i18next";


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StatusIcon = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 2rem;
`
const BText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  margin-top: 1.4rem;
`
const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  max-width: 18.5rem;
  margin-top: 1rem;
`
const ItemTitle = styled.div`
  font-size: 0.875rem;
  color: #5C5C61;
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  word-break: break-all;
`
const SText = styled.div`
  font-size: 0.575rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 15.25rem;
`
const SIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`
const ItemBox = styled.div`
  display: flex;
  width: 19.43rem;
  align-items: center;
  justify-content:space-between;
  height:3.75rem;
  border-bottom:1px solid #EEEEEE;
`
const WBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  padding: 1rem 1.125rem;
  margin: 2rem 1rem;
`
const UsIcon = styled.img`
  width: 1.625rem;
  height: 1.625rem;
`
const LogoIcon =styled.img`
  width: 3.375rem;
  height:1rem;
`
interface SubmitProps{
    type?:string,
    count:number,
    gas:number,
    address:string
}
const Submit = ({type,count,gas,address}:SubmitProps) => {
    const {wallets} = useWallets()
    const {setHasRight} = useNavBarRight()
    const {maticRate} = useMaticRate()
    const {userInfo} = useUserInfo()
    useEffect(() => {
        setHasRight(false)
    },[])
    const Commission = () => {
        if (type === 'web3'){
            return gas
        }
        // @ts-ignore
        if (gas*100<1){
            return 1
        }else {
            // @ts-ignore
            return gas*100
        }
    }
    // @ts-ignore
    const usNum = String(count/100-gas).substring(0,5)
    const { t ,i18n} = useTranslation();
    return (
        <Container>
            <StatusIcon src={require('../../../assets/images/withdrawal/tx_order_state_success.png')}/>
            <BText>The withdrawal application has been submitted</BText>
            <GText>Your withdrawal application is currently under review and is expected to take 1-3 working days. Please be patient and wait. You can view the status of this withdrawal on the withdrawal record page.</GText>
            <WBox>
                {type === 'web3' && <ItemBox>
                    <ItemTitle>{t('Currency')}</ItemTitle>
                    <FlexBox>
                        <UsIcon src={require('../../../assets/images/web3Wallet/tx_icon_coin_USDT.png')}/>
                        <ItemTitle style={{marginLeft:"0.875rem"}}>USDT</ItemTitle>
                    </FlexBox>
                </ItemBox>}
                <ItemBox>
                    <ItemTitle>{t('Amount')}</ItemTitle>
                    <FlexBox>
                        {type === 'web3' ?<div/>: <SIcon  src={require('../../../assets/images/balance/tx_icon_yoo_coin.png')}/>}
                        <ItemText>{type === 'web3'?count + 'USDT':count}</ItemText>
                    </FlexBox>
                </ItemBox>
                <ItemBox >
                    <ItemTitle>{t('Address')}</ItemTitle>
                    {type === 'paypal'?
                    <FlexBox style={{justifyContent:"flex-end",width: '20.625rem'}}>
                        <LogoIcon src={require('../../../assets/images/withdrawal/tx_icon_paypal.png')}/>
                        <ItemText style={{marginLeft:"0.5rem"}}>{userInfo.paypal_acc}</ItemText>
                    </FlexBox>:
                    <SText>{address}</SText>}
                </ItemBox>
                {type !== 'paypal' &&
                <ItemBox>
                    <ItemTitle>{t('Transaction network')}</ItemTitle>
                    <ItemText>Polygon</ItemText>
                </ItemBox>}
                {type !== 'paypal' &&
                <ItemBox>
                    <div>
                        <ItemTitle>{t('Service fee')}</ItemTitle>
                        <GText>{t('Service fee will fluctuate according to Gas price')}</GText>
                    </div>
                    <FlexBox>
                        {type === 'web3' ?<div/>: <SIcon  src={require('../../../assets/images/balance/tx_icon_yoo_coin.png')}/>}
                        <ItemText>{type === 'web3'?BigNumber(gas).toFixed() +' MATIC':Commission()}</ItemText>
                    </FlexBox>
                </ItemBox>}
                <ItemBox>
                    <ItemTitle>{t('Estimated to receive')}</ItemTitle>
                    <ItemText>{type === 'web3'?count-gas: usNum} USDT</ItemText>
                </ItemBox>
            </WBox>
        </Container>
    );
};

export default Submit;
