import React, {useEffect, useState} from 'react';
import BackIcon from "../assets/images/balance/tx_btn_top_back.png"
import HelpIcon from "../assets/images/balance/tx_btn_top_help.png"
import {styled} from 'styled-components'
import {useLocation, useNavigate} from "react-router-dom";
import {useIsBack, useModal, useNavBarRight} from "../hooks";
import NavNoticeModal from "./NavNoticeModal";
import FeedBackIcon from "../assets/images/feedback/feedback_back_icon.png"
import {useTranslation} from "react-i18next";
const Container = styled.div`
  width: 23.43rem;
  height: 2.875rem;
  background:#292930;
  border-radius: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9;
  
`
const LeftIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  padding-left: 0.5rem;
`
const RightIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`
const Title = styled.div`
  font-size: 1rem;
  color: #FFFFFF;
`
const Right = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.625rem;
`
const NavBar = () => {
    const [hasRight,setHasRight] = useState(false)
    const { t ,i18n} = useTranslation();
    const Language = localStorage.getItem('language')
    const {pathname} = useLocation()
    const pathArr = pathname.split('/')
    const title = pathArr[1]
    useEffect(() => {
        if (Language === '3') {
            i18n.changeLanguage('zh')
        }else if (Language === '1') {
            i18n.changeLanguage('en')
        }else if (Language === '4') {
            i18n.changeLanguage('tc')
        }else if (Language === '0') {
            i18n.changeLanguage('id')
        }else {
            i18n.changeLanguage('en')
        }
    },[Language])
    const getTitle = () => {
        if (title === 'giftDetails') {
            return t('Gift Details')
        }else if (title === 'balance') {
            return t('Balance')
        }else if (title === 'withdrawal') {
            return t('Withdrawal')
        } else if (title === 'withdrawalRecords') {
            return t('Withdrawal Records')
        } else if (title === 'withdrawalStatus') {
            return 'Withdrawal Status'
        } else if (title === 'addWithdrawMethod') {
            return 'Add Withdraw Method'
        }  else if (title === 'web3Wallet') {
            return 'Web3 Wallet'
        } else if (title === 'feedback') {
            return t('Feedback')
        } else if (title === 'fb_history') {
            return t('History')
        }else if (title === 'fb_detail') {
            return t('Feedback Details')
        } else if (title === 'upload_mv') {
            return t('Upload Mv')
        }else if (title === 'add_collection_account') {
            return t('Add Recipient Account')
        }else {
            return title
        }

    }
    const navigate = useNavigate()
    const {setOpenModal, setPosition, setModalContent} = useModal()
    function toast() {
        if (title === 'balance' || title === 'feedback' || title === 'upload_mv'){
            window.location.href = "uniwebview://close";
            // window.close();
            //回游戏端
        }else {
            navigate(-1)
        }
    }
    const feedUrl = ['feedback','fb_detail','fb_history','upload_mv']
    return (
        <Container style={{background:feedUrl.includes(title)?'#FFFFFF':'#292930',borderBottom:feedUrl.includes(title) ? '1px solid #D3D3D3':''}} >
            <LeftIcon style={{marginRight:title === 'feedback'?"1.875rem":0}}  onClick={toast} src={feedUrl.includes(title)?FeedBackIcon:BackIcon}/>
            <Title  style={{color:feedUrl.includes(title)?'#43474E':'#FFFFFF',fontWeight:feedUrl.includes(title)?"bold":"normal"}}>{getTitle()}</Title>
            {title === 'feedback' && <div onClick={() => navigate('/fb_history')} style={{fontSize:'0.75rem',color:'#43474E',marginRight:"0.75rem",fontWeight:feedUrl.includes(title)?"bold":"normal"}}>{t('History')}</div>}
            {title !== 'feedback' &&
            <div>
                 <Right/>
            </div>}

        </Container>
    );
};

export default NavBar;
