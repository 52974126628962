import React, {useEffect} from 'react';
import styled from "styled-components";
import Bg from "../../assets/images/follow_me/n_gzapp_bg_1.png"
import LogBg from "../../assets/images/follow_me/n_gzapp_title.png"
import {useWindowSize} from "../../useWindowSize";
import FlexBox from "../../commen/FlexBox";


const Container = styled.div`
    background-image: url(${Bg});
    background-size: cover;
    width: 23.5rem;
    min-height: ${props => props.height}px;
    display: flex;
    justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
`
const LogoBg = styled.img`
    width: 22.5rem;
    height: 11.25rem;
  object-fit: cover;
  margin-top: 2rem;
`
const Title = styled.div`
    font-size: 1.75rem;
  font-weight: bold;
  color: #FFFFFF;
  max-width: 17rem;
  text-align:center;
  margin-top: 2rem;
`
const Text =styled.div`
  font-size: 1rem;
  color: #FFFFFF;
  max-width: 20rem;
  text-align:center;
  line-height: 1.25rem;
`
const Btn = styled.div`
  width: 14.85rem;
  height: 2.44rem;
  background: #FFFFFF;
  border-radius: 1.5rem;
  text-align: center;
  line-height: 2.44rem;
  position: relative;
  font-size: 1.125rem;
  color: #5D46E9;
  margin-top: 5rem;
`
const BtnIcon = styled.img`
  width: 1.55rem;
  height: 1.55rem;
  object-fit: cover;
  position: absolute;
  top:-0.5rem;
  left: -0.5rem;
`
const Image=styled.img`
  width: 15rem;
  height: 10rem;
  object-fit: cover;
  margin-top: 1rem;
`
const BackIcon = styled.img`
    width: 1.75rem;
    height: 1.75rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  
`
const Emj = styled.img`
  width: 1rem;
  height: 1rem;
  object-fit: cover;
  padding:0 0.125rem;
`
const FollowMe = () => {
    const {height} = useWindowSize()

    function jump(link) {
        // 创建一个隐藏的a标签，并设置其href为外部链接的URL
        const a = document.createElement('a');
        a.setAttribute('href', link);
        a.setAttribute('target', '_blank'); // 通过设置target为'_blank'来在新窗口或新标签页打开链接
        a.style.display = 'none';

// 将创建的a标签添加到body中
        document.body.appendChild(a);

// 模拟用户点击该a标签，触发打开外部链接
        a.click();

// 清除添加的a标签
        document.body.removeChild(a);

    }

    return (
        <Container height={height}>
            <BackIcon onClick={() => window.location.href = "uniwebview://close"} src={require('../../assets/images/follow_me/Back.png')}/>
            <LogoBg src={LogBg}/>
            <Title>Saluran Media Sosial Resmi PartyYoo</Title>
            <Text style={{marginTop:"1.75rem"}}>Ikuti saluran media sosial resmi PartyYoo</Text>
            <Text>Ikuti terus berita dan acara terbaru PartyYoo</Text>
            <Text style={{marginTop:"1.25rem"}}>Dapatkan lebih banyak teman</Text>
            <FlexBox>
                <Emj src={require('../../assets/images/follow_me/n_gzapp_icon_emj_1.png')}/>
                <Text>Jadikan pestanya semakin seru!</Text>
                <Emj src={require('../../assets/images/follow_me/n_gzapp_icon_emj_1.png')}/>

            </FlexBox>
            <Btn onClick={() => jump('https://www.tiktok.com/@partyyoo.id')}><BtnIcon src={require('../../assets/images/follow_me/n_gzapp_img_tiktok_2.png')}/>TikTok</Btn>
            <Image onClick={() => jump('https://www.tiktok.com/@partyyoo.id')} src={require('../../assets/images/follow_me/n_gzapp_img_tiktok.png')}/>
            <Btn onClick={() => jump('https://www.instagram.com/partyyoo.id/')}><BtnIcon src={require('../../assets/images/follow_me/n_gzapp_img_instagram_2.png')}/>Instagram</Btn>
            <Image onClick={() => jump('https://www.instagram.com/partyyoo.id/')} src={require('../../assets/images/follow_me/n_gzapp_img_instagram.png')}/>
            <Btn onClick={() => jump('https://t.me/+PwS3G9TGTJc5NmQ9')}><BtnIcon src={require('../../assets/images/follow_me/n_gzapp_img_telegram_2.png')}/>Telegram</Btn>
            <Image onClick={() => jump('https://t.me/+PwS3G9TGTJc5NmQ9')} src={require('../../assets/images/follow_me/n_gzapp_img_telegram.png')}/>
        </Container>
    );
};

export default FollowMe;
