import fbrequest from "../utils/feedbackReq";



export const getAmazonPutUrl= (params) => {
    return fbrequest('/upload/get_amazon_s3_put_url',
        {
            method:'GET',
            params
        })
}

export const createFeedback = (params) => {
    return fbrequest('/feedback/create_feedback',
        {
            method:'GET',
            params
        })
}


export const getFeedbackList = (params) => {
    return fbrequest('/feedback/get_feedbacks',
        {
            method:'GET',
            params
        })
}

export const getFeedbackDetail = (params) => {
    return fbrequest('/feedback/get_feedback',
        {
            method:'GET',
            params
        })
}


export const getUploadMvUrl = (params) => {
    return fbrequest('/mv/get_amazon_s3_put_url_upload_mv',
        {
            method:'GET',
            params
        })
}

export const updateMvStatus = (params) => {
    return fbrequest('/mv/update_mv_status',
        {
            method:'GET',
            params
        })
}
