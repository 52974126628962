import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useWindowSize} from "../../../useWindowSize";
import {getFeedbackDetail} from "../../../services/feedback";
import {getToken} from "../../../utils/token";
import {useParams,} from "react-router-dom";
import {ImageViewer} from "antd-mobile"
// 密码MD5加密
import md5 from 'js-md5';

const Container = styled.div`
  padding: 0 1rem;
  background-color: #FFFFFF;
`
const Content = styled.div`
  color: #43474E;
  font-size: 1rem;
`
const Img = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 0.75rem;
`

const FbDetail = () => {
    const {height} = useWindowSize()
    const RoleId = localStorage.getItem('roleId')
    const ShortId = localStorage.getItem('shortId')
    const [data,setData] = useState({})
    const [showImg,setShowImg] = useState(false)
    const [showRImg,setShowRImg] = useState(false)

    const {id} = useParams()
    const imgViewerRef = useRef(null)
    const bImgViewerRef = useRef(null)
    const Token = getToken()
    const time = new Date().getTime()
    const sign = RoleId + Token + time

    useEffect(() => {
        getFeedbackDetail({FId:id,RId:RoleId,Token:Token,Timestamp:time,Sign:md5(sign)}).then(res => {
            if (res.data.code === 0) {
                setData(res.data.data)
            }
        })
    },[])

    return (
        <Container style={{minHeight:height}}>
            <div style={{borderBottom:data.IsReply?"1px solid #F3F3F3":"",margin:"1rem 0rem",paddingBottom:"1rem"}}>
                <Content>{data.Content}</Content>
                {data.ImageUrls?.length > 0 &&
                <FlexBox style={{justifyContent:"flex-start",margin:"1rem 0",}}>
                    {
                        data.ImageUrls.map((item,index) => {
                            return (
                                <Img onClick={() => {imgViewerRef.current.swipeTo(index);setShowImg(true)}} src={item} key={index}/>
                            )
                        })
                    }
                </FlexBox>}
                <ImageViewer.Multi
                    ref={imgViewerRef}
                    images={data.ImageUrls}
                    visible={showImg}
                    defaultIndex={1}
                    onClose={() => {
                        setShowImg(false)
                    }}
                />
            </div>
            {data.IsReply &&
            <div style={{marginTop:"1rem"}}>
                <Content>
                    {data.ReplyName?data.ReplyName:'Party Yoo'}:{data.Reply}
                </Content>
                {data.ReplyImageUrls?.length > 0 &&
                <FlexBox style={{justifyContent:"flex-start",margin:"rem 0",}}>
                    {
                        data.ReplyImageUrls.map((item,index) => {
                            return (
                                <Img onClick={() => {bImgViewerRef.current.swipeTo(index);setShowRImg(true)}} src={item} key={index}/>
                            )
                        })
                    }
                </FlexBox>}
                <ImageViewer.Multi
                    ref={bImgViewerRef}
                    images={data.ReplyImageUrls}
                    visible={showRImg}
                    defaultIndex={1}
                    onClose={() => {
                        setShowRImg(false)
                    }}
                />
            </div>}
        </Container>
    );
};

export default FbDetail;
