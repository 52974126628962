import request from "../utils/request";
// http://118.178.187.93:5003/ticket/balance?RoleId=1894189755604729859
interface balance {
    RoleId:string | null
}
export const getBalance= (params:balance) => {
    return request('/ticket/balance',
        {
            method:'GET',
            params
        })
}
