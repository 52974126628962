import request from "../utils/request";



export const submitInfo = (params:any) => {
    return request('/ticket/withdraw',{
        method:'GET',
        params
    })
}
