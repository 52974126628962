import { ServiceProto } from 'tsrpc-proto';
import { ReqBindPaypal, ResBindPaypal } from './bind_acc/PtlBindPaypal';
import { ReqBindFinancialAccount, ResBindFinancialAccount } from './financial/PtlBindFinancialAccount';
import { ReqDeleteFinancialAccount, ResDeleteFinancialAccount } from './financial/PtlDeleteFinancialAccount';
import { ReqGetBankAccounts, ResGetBankAccounts } from './financial/PtlGetBankAccounts';
import { ReqGetEWalletAccounts, ResGetEWalletAccounts } from './financial/PtlGetEWalletAccounts';
import { ReqCheckPayPsw, ResCheckPayPsw } from './pay/PtlCheckPayPsw';
import { ReqSetPayPsw, ResSetPayPsw } from './pay/PtlSetPayPsw';
import { ReqBindAccount, ResBindAccount } from './PtlBindAccount';
import { ReqBindWallAddress, ResBindWallAddress } from './PtlBindWallAddress';
import { ReqCheckAcc, ResCheckAcc } from './PtlCheckAcc';
import { ReqCheckCode, ResCheckCode } from './PtlCheckCode';
import { ReqCheckRoleCode, ResCheckRoleCode } from './PtlCheckRoleCode';
import { ReqGetCheckCode, ResGetCheckCode } from './PtlGetCheckCode';
import { ReqGetCode, ResGetCode } from './PtlGetCode';
import { ReqGetRoleCode, ResGetRoleCode } from './PtlGetRoleCode';
import { ReqGetSdkCode, ResGetSdkCode } from './PtlGetSdkCode';
import { ReqGetYouTobeUrl, ResGetYouTobeUrl } from './PtlGetYouTobeUrl';
import { ReqLogin, ResLogin } from './PtlLogin';
import { ReqSeeFirstDw, ResSeeFirstDw } from './PtlSeeFirstDw';
import { ReqTest, ResTest } from './PtlTest';
import { ReqChangeFirst_dw, ResChangeFirst_dw } from './server/PtlChangeFirst_dw';
import { ReqChangefirst_OrderID, ResChangefirst_OrderID } from './server/PtlChangefirst_OrderID';

export interface ServiceType {
    api: {
        "bind_acc/BindPaypal": {
            req: ReqBindPaypal,
            res: ResBindPaypal
        },
        "financial/BindFinancialAccount": {
            req: ReqBindFinancialAccount,
            res: ResBindFinancialAccount
        },
        "financial/DeleteFinancialAccount": {
            req: ReqDeleteFinancialAccount,
            res: ResDeleteFinancialAccount
        },
        "financial/GetBankAccounts": {
            req: ReqGetBankAccounts,
            res: ResGetBankAccounts
        },
        "financial/GetEWalletAccounts": {
            req: ReqGetEWalletAccounts,
            res: ResGetEWalletAccounts
        },
        "pay/CheckPayPsw": {
            req: ReqCheckPayPsw,
            res: ResCheckPayPsw
        },
        "pay/SetPayPsw": {
            req: ReqSetPayPsw,
            res: ResSetPayPsw
        },
        "BindAccount": {
            req: ReqBindAccount,
            res: ResBindAccount
        },
        "BindWallAddress": {
            req: ReqBindWallAddress,
            res: ResBindWallAddress
        },
        "CheckAcc": {
            req: ReqCheckAcc,
            res: ResCheckAcc
        },
        "CheckCode": {
            req: ReqCheckCode,
            res: ResCheckCode
        },
        "CheckRoleCode": {
            req: ReqCheckRoleCode,
            res: ResCheckRoleCode
        },
        "GetCheckCode": {
            req: ReqGetCheckCode,
            res: ResGetCheckCode
        },
        "GetCode": {
            req: ReqGetCode,
            res: ResGetCode
        },
        "GetRoleCode": {
            req: ReqGetRoleCode,
            res: ResGetRoleCode
        },
        "GetSdkCode": {
            req: ReqGetSdkCode,
            res: ResGetSdkCode
        },
        "GetYouTobeUrl": {
            req: ReqGetYouTobeUrl,
            res: ResGetYouTobeUrl
        },
        "Login": {
            req: ReqLogin,
            res: ResLogin
        },
        "SeeFirstDw": {
            req: ReqSeeFirstDw,
            res: ResSeeFirstDw
        },
        "Test": {
            req: ReqTest,
            res: ResTest
        },
        "server/ChangeFirst_dw": {
            req: ReqChangeFirst_dw,
            res: ResChangeFirst_dw
        },
        "server/Changefirst_OrderID": {
            req: ReqChangefirst_OrderID,
            res: ResChangefirst_OrderID
        }
    },
    msg: {

    }
}

export const serviceProto: ServiceProto<ServiceType> = {
    "version": 24,
    "services": [
        {
            "id": 11,
            "name": "bind_acc/BindPaypal",
            "type": "api",
            "conf": {}
        },
        {
            "id": 18,
            "name": "financial/BindFinancialAccount",
            "type": "api",
            "conf": {}
        },
        {
            "id": 21,
            "name": "financial/DeleteFinancialAccount",
            "type": "api",
            "conf": {}
        },
        {
            "id": 19,
            "name": "financial/GetBankAccounts",
            "type": "api",
            "conf": {}
        },
        {
            "id": 20,
            "name": "financial/GetEWalletAccounts",
            "type": "api",
            "conf": {}
        },
        {
            "id": 8,
            "name": "pay/CheckPayPsw",
            "type": "api",
            "conf": {}
        },
        {
            "id": 9,
            "name": "pay/SetPayPsw",
            "type": "api",
            "conf": {}
        },
        {
            "id": 0,
            "name": "BindAccount",
            "type": "api",
            "conf": {}
        },
        {
            "id": 10,
            "name": "BindWallAddress",
            "type": "api",
            "conf": {}
        },
        {
            "id": 1,
            "name": "CheckAcc",
            "type": "api",
            "conf": {}
        },
        {
            "id": 5,
            "name": "CheckCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 15,
            "name": "CheckRoleCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 6,
            "name": "GetCheckCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 2,
            "name": "GetCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 16,
            "name": "GetRoleCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 7,
            "name": "GetSdkCode",
            "type": "api",
            "conf": {}
        },
        {
            "id": 17,
            "name": "GetYouTobeUrl",
            "type": "api",
            "conf": {}
        },
        {
            "id": 3,
            "name": "Login",
            "type": "api",
            "conf": {}
        },
        {
            "id": 12,
            "name": "SeeFirstDw",
            "type": "api",
            "conf": {}
        },
        {
            "id": 4,
            "name": "Test",
            "type": "api",
            "conf": {}
        },
        {
            "id": 13,
            "name": "server/ChangeFirst_dw",
            "type": "api",
            "conf": {}
        },
        {
            "id": 14,
            "name": "server/Changefirst_OrderID",
            "type": "api",
            "conf": {}
        }
    ],
    "types": {
        "bind_acc/PtlBindPaypal/ReqBindPaypal": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "bind",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "../../protocols/base/BaseRequest": {
            "type": "Interface",
            "properties": [
                {
                    "id": 1,
                    "name": "AuthHash",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 0,
                    "name": "__token",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "bind_acc/PtlBindPaypal/ResBindPaypal": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "../../protocols/base/BaseResponse": {
            "type": "Interface"
        },
        "financial/PtlBindFinancialAccount/ReqBindFinancialAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "fAccount",
                    "type": {
                        "type": "Reference",
                        "target": "../../interface/face_FinancialAccount/face_FinancialAccount"
                    }
                }
            ]
        },
        "../../interface/face_FinancialAccount/face_FinancialAccount": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "user_id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "account_type",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Literal",
                                    "literal": "BANK_ACCOUNT"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal",
                                    "literal": "E_WALLET"
                                }
                            }
                        ]
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "payment_type",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 13,
                    "name": "institution_target",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "account_number",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "bank_code",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "bank_name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "bank_branch",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "bank_city",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "e_wallet_type",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "check_digit",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "full_name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 11,
                    "name": "email",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "financial/PtlBindFinancialAccount/ResBindFinancialAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "financial/PtlDeleteFinancialAccount/ReqDeleteFinancialAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "financialId",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "financial/PtlDeleteFinancialAccount/ResDeleteFinancialAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "message",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "financial/PtlGetBankAccounts/ReqGetBankAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "financial/PtlGetBankAccounts/ResGetBankAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "bankAccounts",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../../interface/face_BankAccountData/face_BankAccountData"
                        }
                    }
                }
            ]
        },
        "../../interface/face_BankAccountData/face_BankAccountData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 7,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 8,
                    "name": "payment_type",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "institution_target",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "account_number",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "bank_code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "bank_name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "bank_branch",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "bank_city",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "full_name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "email",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "financial/PtlGetEWalletAccounts/ReqGetEWalletAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "financial/PtlGetEWalletAccounts/ResGetEWalletAccounts": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "eWalletAccounts",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../../interface/face_EWalletAccountData/face_EWalletAccountData"
                        }
                    }
                }
            ]
        },
        "../../interface/face_EWalletAccountData/face_EWalletAccountData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 3,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 0,
                    "name": "account_number",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "e_wallet_type",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "check_digit",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "full_name",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "email",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "pay/PtlCheckPayPsw/ReqCheckPayPsw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "psw",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pay/PtlCheckPayPsw/ResCheckPayPsw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "check",
                    "type": {
                        "type": "Boolean"
                    }
                }
            ]
        },
        "pay/PtlSetPayPsw/ReqSetPayPsw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "psw",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pay/PtlSetPayPsw/ResSetPayPsw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "PtlBindAccount/ReqBindAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "bindAccount",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "bindCode",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlBindAccount/ResBindAccount": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "__token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlBindWallAddress/ReqBindWallAddress": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "binAddress",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlBindWallAddress/ResBindWallAddress": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "PtlCheckAcc/ReqCheckAcc": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "fromChannel",
                    "type": {
                        "type": "Reference",
                        "target": "PtlBindAccount/fromChannel"
                    }
                },
                {
                    "id": 1,
                    "name": "fromAccount",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlBindAccount/fromChannel": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "acc_mail"
                },
                {
                    "id": 1,
                    "value": "i_action"
                },
                {
                    "id": 2,
                    "value": "i_part"
                },
                {
                    "id": 3,
                    "value": "paypal_acc"
                },
                {
                    "id": 4,
                    "value": "wall_address"
                },
                {
                    "id": 5,
                    "value": "payermax_bank"
                },
                {
                    "id": 6,
                    "value": "payermax_wallet"
                }
            ]
        },
        "PtlCheckAcc/ResCheckAcc": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "info",
                    "type": {
                        "type": "Reference",
                        "target": "../../interface/face_Account/face_Account"
                    }
                }
            ]
        },
        "../../interface/face_Account/face_Account": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "acc_mail",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "i_action",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 3,
                    "name": "i_part",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 6,
                    "name": "paypal_acc",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 4,
                    "name": "pay_psw",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 5,
                    "name": "wall_address",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 9,
                    "name": "payermax_bank",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 10,
                    "name": "payermax_wallet",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 7,
                    "name": "first_dw",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Number"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 8,
                    "name": "first_OrderID",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "PtlCheckCode/ReqCheckCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "acc",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlCheckCode/ResCheckCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "PtlCheckRoleCode/ReqCheckRoleCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "role_id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlCheckRoleCode/ResCheckRoleCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "__token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetCheckCode/ReqGetCheckCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "acc",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetCheckCode/ResGetCheckCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "PtlGetCode/ReqGetCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "bindType",
                    "type": {
                        "type": "Reference",
                        "target": "PtlBindAccount/bindType"
                    }
                },
                {
                    "id": 1,
                    "name": "bindAccount",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "fromChannel",
                    "type": {
                        "type": "Reference",
                        "target": "PtlBindAccount/fromChannel"
                    }
                },
                {
                    "id": 3,
                    "name": "fromAccount",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlBindAccount/bindType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": 0
                }
            ]
        },
        "PtlGetCode/ResGetCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "PtlGetRoleCode/ReqGetRoleCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "role_id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetRoleCode/ResGetRoleCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "info",
                    "type": {
                        "type": "Reference",
                        "target": "../../interface/face_Account/face_Account"
                    }
                }
            ]
        },
        "PtlGetSdkCode/ReqGetSdkCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "acc",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetSdkCode/ResGetSdkCode": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetYouTobeUrl/ReqGetYouTobeUrl": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlGetYouTobeUrl/ResGetYouTobeUrl": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "seconds",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "url",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "urls",
                    "type": {
                        "type": "Interface",
                        "indexSignature": {
                            "keyType": "String",
                            "type": {
                                "type": "String"
                            }
                        }
                    }
                },
                {
                    "id": 4,
                    "name": "videoName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "msg",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlLogin/ReqLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "PtlLogin/ResLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "__token",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "info",
                    "type": {
                        "type": "Reference",
                        "target": "../../interface/face_Account/face_Account"
                    }
                },
                {
                    "id": 2,
                    "name": "url",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "PtlSeeFirstDw/ReqSeeFirstDw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "PtlSeeFirstDw/ResSeeFirstDw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "Number"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                },
                {
                    "id": 1,
                    "name": "id",
                    "type": {
                        "type": "Union",
                        "members": [
                            {
                                "id": 0,
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "type": {
                                    "type": "Literal"
                                }
                            }
                        ]
                    }
                }
            ]
        },
        "PtlTest/ReqTest": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ]
        },
        "PtlTest/ResTest": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "server/PtlChangeFirst_dw/ReqChangeFirst_dw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "serverKey",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "code",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "role_id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "server/PtlChangeFirst_dw/ResChangeFirst_dw": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        },
        "server/PtlChangefirst_OrderID/ReqChangefirst_OrderID": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "serverKey",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "order_id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "role_id",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "server/PtlChangefirst_OrderID/ResChangefirst_OrderID": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../../protocols/base/BaseResponse"
                    }
                }
            ]
        }
    }
};