import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useInitWallet, useIsBack, useMaticRate, useModal, useUserInfo, useWallets} from "../../../hooks";
// import SelectWalletModal from "./SelectWalletModal";
import WithModalComponent from "./WithModalComponent";
import wallet from "../../../utils/wallet";
import {AoasWallet, utils} from "aoas-web-sdk";
import {Toast} from "antd-mobile";
import BigNumber from "bignumber.js";
import {setToAddress} from "../../../utils/toAddress";
import SetPwModal from "./SetPwModal";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  margin: 0.625rem;
  
`

const Title = styled.div`
  font-size: 0.875rem;
  color: #232323;
  margin-top: 1.25rem;
`

const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 10.125rem;
`
const CopyIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`

const SText = styled.div`
  font-size: 0.575rem;
  color: #292930;
`
const SIcon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
`
const ItemBox = styled.div`
  display: flex;
  width: 20.875rem;
  align-items: center;
  justify-content:space-between;
  background: #FFFFFF;
  height:4.0625rem;
  border-bottom:1px solid #EEEEEE;
  padding: 0 0.625rem;
  border-radius: 0.625rem;
`
const WBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 1rem;
`
const ItemTitle = styled.div`
  font-size: 0.875rem;
  color: #292930;
`
const Input = styled.input`
  flex:1;
  height: 2rem;
  outline: none;
  border: none;
  color: #232323;
  font-size: 1rem;
`
const ArrowIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const SGText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  margin-top: 1.25rem;
`
const GText = styled.div`
  font-size: 0.875rem;
  color: #99999C;
  border-right: 1px solid #EEEEEE;
  padding-right: 0.75rem;
`
const CText = styled.div`
  font-size: 0.625rem;
  color: #5C46FF;
  margin-left: 0.75rem;
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  margin-top: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const Web3Withdraw = ({submit,setSubmit,usdt,callback,callCount,callGas}:{submit?:boolean,setSubmit:() => void,usdt?:number,callback:(toAddress:string) => void,callCount?:(count:string) => void,callGas?:(gas:number) =>void}) => {
    const defaultAddress = localStorage.getItem('defaultAddress')
    const [myAddress, setMyAddress] = useState(defaultAddress?defaultAddress:'')
    const [gl,setGl] = useState(0)
    const [gp,setGp] = useState(0)
    const [count,setCount] = useState('')
    const {setOpenModal,setModalContent,setPosition} = useModal()
    const [decimalArr,setDecimalArr] = useState([])
    const [correctAddress,setCorrectAddress] = useState(false)
    const {initWallet} = useInitWallet()
    const {maticRate} = useMaticRate()
    const {userInfo} = useUserInfo()
    const getPrice = () => {
        wallet.getTokenList().then((res:any) => {
            let addressArr:any[] = []
            res.forEach((item:any) => addressArr.push(item.decimals))
            // @ts-ignore
            setDecimalArr(addressArr)
            let decimals = addressArr[0]
            wallet.getGasLimit(utils.bigTrans16(Number(count),decimals)).then(res => {
                setGl(utils.bigDivided(res,decimals))

            })
            wallet.getGasPrice().then(res => {
                setGp(utils.bigDivided(res,decimals))
            })
        });
    }
    const gas = gl*gp
    // 0.000000000000000000000031500000336
    const Commission = () => {
        const num1 = new BigNumber(String(gl))
        const num2 = new BigNumber(String(gp))
        return Number(num1.times(num2).toFixed())
    }
    useEffect(() => {
        try {
                getPrice()

        }catch (err){

        }

    },[])
    useEffect(() => {
        if (callCount){
            callCount(count)
        }
        if (callGas){
            callGas(Commission())
        }

    },[count,gas])
    useEffect(() => {
        callback(myAddress)

    },[myAddress])
    const { t ,i18n} = useTranslation();
    const check = (address:string) => {
        AoasWallet.checkAddress({
            chainId: '80001',
            chainType: 'evm_chain',
            address: address, // 钱包地址
        }, {
            onSuccess: (data) => {
                Toast.show({icon:'success',content:t('Your wallet address is incorrect')})
                setCorrectAddress(true)
                setToAddress(myAddress);
                localStorage.setItem('defaultAddress',myAddress)
            },
            onFailure: (err) => {
                Toast.show({icon:'fail',content:t('Your wallet address is incorrect')})
                setCorrectAddress(false)
            }
        })

    }
    const openWithModal = () => {
        setModalContent(<WithModalComponent com={Commission()} toAddress={myAddress}   usCount={Number(count)} setSubmit={setSubmit} type={'web3'}/>);setPosition('bottom');setOpenModal(true)
    }

    return (
        <Container>
            <Title onClick={() => { }}>{t('Wallet Address')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                <FlexBox>
                    <SIcon src={require('../../../assets/images/web3Wallet/tx_icon_coin_USDT.png')}/>
                    <ItemTitle style={{marginLeft:"0.875rem"}}>USDT</ItemTitle>
                </FlexBox>
            </ItemBox>
            <Title>{t('Withdrawal address')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                <Input value={myAddress} onChange={(e:any) => {setMyAddress(e.target.value);}} type={'text'} placeholder={t('Please enter withdrawal address')} />
                {/*<ArrowIcon onClick={() => {setModalContent(<SelectWalletModal callback={(address) => {setMyAddress(address);setToAddress(address);localStorage.setItem('defaultAddress',address)}}/>);setPosition('bottom');setOpenModal(true)}} src={require('../../../assets/images/web3Wallet/tx_icon_extend_arrow_shu.png')}/>*/}
            </ItemBox>
            <Title>{t('Transaction network')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                    <ItemTitle>Polygon</ItemTitle>
            </ItemBox>
            <FlexBox style={{width:"20.875rem",justifyContent:"space-between"}}>
                <Title>{t('Transaction network')}</Title>
                <SGText>Available:{usdt} USDT</SGText>
            </FlexBox>

            <ItemBox style={{marginTop:"1rem"}}>
                <Input value={count}  onFocus={() => {check(myAddress)}} onChange={(e:any) => setCount(e.target.value)} type={'text'} placeholder={'Minimum 5'} />
                <FlexBox>
                    <GText>USDT</GText>
                    <CText onClick={() => {setCount(String(usdt))}}>{t('All')}</CText>
                </FlexBox>
            </ItemBox>
            <Title>{t('Service fee')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                <ItemTitle>{(gas!==0 && count!=='')?BigNumber(gas).toFixed():''} MATIC</ItemTitle>
            </ItemBox>
            <Title>{t('Estimated to receive')}</Title>
            <ItemBox style={{marginTop:"1rem"}}>
                <ItemTitle>{count}</ItemTitle>
                <GText>USDT</GText>
            </ItemBox>
            <FlexBox>
                <Btn color={(count!==''&&myAddress!=='') ? '#5C46FF':'#E5E2E9'} onClick={() => {
                    if (count === ''){
                        Toast.show({icon:'fail',content:t('Please enter the amount')})
                        return;
                    }

                    if (Number(count)<5){
                        Toast.show({icon:'fail',content:t('USDT is less than 5 and cannot be withdrawn')})
                        return;
                    }
                    if (!correctAddress){
                        Toast.show({icon:'fail',content:t('Your wallet address is incorrect')})
                        return;
                    }
                    if (userInfo.pay_psw === '0'){
                        setModalContent(<SetPwModal openWithModal={openWithModal}/>)
                        setPosition('center')
                        setOpenModal(true);
                        return;
                    }
                   openWithModal()
                }}>{t('Withdraw')}</Btn>
            </FlexBox>

        </Container>
    );
};

export default Web3Withdraw;
