import {Toast} from "antd-mobile";

export function copy(text) {
    try {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        Toast.show({icon:'success',content:'Copy success'},)
    } catch (err){
        Toast.show({icon:'fail',content:'Copy failed'},)
    }

}
