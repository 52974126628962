import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../commen/FlexBox";
import {useModal, useRoleId, useUserInfo, useWallets} from "../../hooks";
import WithModalComponent from "./components/WithModalComponent";
import Submit from "./components/Submit";
import NoticeModal from "./components/NoticeModal";
import YHNoticeModal from "./components/YHNoticeModal";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Web3Withdraw from "./components/Web3Withdraw";
import {Toast} from "antd-mobile";
import SetPwModal from "./components/SetPwModal";
import CheckInstitutionModal from "../addCollectionAccount/components/checkInstitutionModal";
import CheckAccountModal from "./components/CheckAccountModal";
import {mailClient} from "../../services/client";
import {submitInfo} from "../../services/submit";
import {useTranslation} from "react-i18next";
import {isMultipleOfTen} from "../../utils";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F4F4F8;
  
`
const BalanceBox = styled.div`
  width: 19.5rem;
  height: 6.8375rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
`
const BText = styled.div`
  font-size: 1rem;
  color: #292930;
`
const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp:2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical;
`
const CText = styled.div`
  font-size: 0.625rem;
  color: #5C46FF;
`
const BIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`
const SIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`
const Input = styled.input`
  width: 10.5rem;
  height: 2.25rem;
  border: none;
  outline: none;
  color: #292930;
  font-size: 1rem;
  text-indent: 0.5rem;
`
const CheckItem = styled.div`
  width: 20.625rem;
  height: 5.25rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0.875rem;
  position: relative;
  &:hover {
    filter: opacity(0.8);
  }
`
const Radio = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  background:${props => props.color};
  border-radius: 0.75rem;
`
const ItemTitle = styled.div`
  font-size: 0.5625rem;
  color: #292930;
`
const ItemText = styled.div`
  font-size: 0.5rem;
  color: #99999C;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp:2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical;
`
const Tips = styled.div`
  background: #9155FF;
  border-radius: 0.5rem;
  text-align: center;
  line-height: 0.875rem;
  position: absolute;
  left: 0;
  top: -0.4375rem;
  color:#F4F4F8;
  padding: 0.1rem 0.25rem;
`
const AddIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
`
const LogoIcon =styled.img`
  width: 3.375rem;
  height:1rem;
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  position: fixed;
  bottom: 2rem;
  left: 4.075rem;
  &:hover {
    background: #5C46FF;
  }
`
const AddAccount = styled.div`
  width: 21.25rem;
  height: 3.06rem;
  background: #FFFFFF;
  border: 0.13px solid #EEEEEE;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`
const WBox = styled.div`
  width: 19.5rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.652rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.5rem;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`
const NameText = styled.div`
  font-size: 1rem;
  color: #99999C;
`

const Withdrawal = () => {
    const location = useLocation()
    const {wType} = location.state
    const [count,setCount]= useState('')
    const [checkIndex,setCheckIndex] = useState(0)
    const [toAddress,setToAddress] = useState('')
    const {setOpenModal,setModalContent,setPosition} =useModal()
    const [refresh,setRefresh] = useState(false)
    const getSubmit = sessionStorage.getItem('submit')
    const [submit,setSubmit] = useState(getSubmit?getSubmit:'0')
    const [accountList,setAccountList] = useState([])
    const [checkAccount,setCheckAccount] = useState(wType===0?{id:0,bank_name:'',bank_account:'',full_name:'',email:''}:{id:0,account_number:'',e_wallet_type:'',full_name:'',email:''})
    const navigate =useNavigate()
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type')
    const balance = Number(location.state.balance?.BalanceTicket/100).toLocaleString()
    const canBalance= location.state.balance?.BalanceTicket/100-location.state.balance?.FrozenTicket14Day/100
    const [gasNum,setGasNum] = useState(0)
    const {userInfo} = useUserInfo()
    const {wallets}=useWallets()
    const {roleId} = useRoleId()
    const localRoleId = localStorage.getItem('roleId')
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        sessionStorage.setItem('submit',String(submit))
    },[submit])
    useEffect(() => {
        return () => {
            sessionStorage.removeItem('submit')
        }
    },[])
    useEffect(() => {
        if (wType === 0){
            mailClient.callApi('financial/GetBankAccounts',{}).then((res) => {
                if (res.isSucc){
                    // @ts-ignore
                    setAccountList(res.res.bankAccounts)
                    // @ts-ignore
                    if (res.res.bankAccounts.length > 0) {
                        // @ts-ignore
                        setCheckAccount(res.res.bankAccounts[0])
                    }

                }
                console.log(res)
            })
        }else {
            mailClient.callApi('financial/GetEWalletAccounts',{}).then((res) => {
                if (res.isSucc){
                    // // @ts-ignore
                    // @ts-ignore
                    setAccountList(res.res.eWalletAccounts)
                    // // @ts-ignore
                    // @ts-ignore
                    if (res.res.eWalletAccounts.length > 0) {
                        // @ts-ignore
                        setCheckAccount(res.res.eWalletAccounts[0])
                    }

                }
                console.log(res)
            })
        }


    },[refresh,wType])
    const onRefresh = useCallback(() => {
        setRefresh(!refresh)
    },[])
    const openWithModal = () => {
        if (checkIndex === 0){
            setModalContent(<WithModalComponent  checkIndex={checkIndex} callback={(gas) => {setGasNum(gas)}}  count={count}  setSubmit={() => setSubmit('1')}/>);setPosition('bottom');setOpenModal(true)
        }else {
            setModalContent(<WithModalComponent checkIndex={checkIndex} type={'paypal'} callback={(gas) => {setGasNum(gas)}} count={count} setSubmit={() => setSubmit('1')}/>);setPosition('bottom');setOpenModal(true)
        }
    }

    const withdraw = () => {
        if (checkAccount.id === 0) {
            return Toast.show({icon: 'fail',content:t('Please select an account')})
        }
        if (!isMultipleOfTen(Number(count)) || Number(count) < 0){
            return  Toast.show({icon: 'fail',content:t('The withdrawal quantity must be a multiple of 10 and greater than 0')})
        }
        if (Number(count) < 2500){
            Toast.show({icon: 'fail',content:t('The minimum withdrawal amount per time is 2500 Yoo Coins (2.5 USD)')})
            return;
        }
        if (Number(count) > 50000){
            Toast.show({icon: 'fail',content:t('The maximum amount for each withdrawal is 50,000 Yoo Coins (500 USD)')})
            return;
        }
        if (count === ''){
            Toast.show({icon: 'fail',content:t('Please enter withdrawal amount')})
            return;
        }
        // if (!userInfo.paypal_acc && checkIndex === 1){
        //     navigate('/addWithdrawMethod')
        //     return;
        // }
        // if (userInfo.pay_psw === '0'){
        //     setModalContent(<SetPwModal openWithModal={openWithModal}/>)
        //     setPosition('center')
        //     setOpenModal(true);
        //     return;
        // }
        // openWithModal()
        Toast.show({icon: 'loading',content:'Submitting...'})
        submitInfo({ExtType:wType===0?"payermax_bank":"payermax_wallet",Financial_Id:String(checkAccount.id),RoleId:roleId?roleId:localRoleId,Count:Number(count)*100,Timestamp:new Date().getTime()}).then(res => {
            if (res.data.code === 0){
                Toast.show({icon:'success',content:t('Submit successful')})
                setOpenModal(false)
                // @ts-ignore
                // setSubmit()
                setTimeout(() => {
                    navigate(-1)
                },500)
            }
        }).catch(err => {
            Toast.show({icon:'fail',content:t('Submit failed')})
            setOpenModal(false)
        })
    }

    if (type === 'web3') {
        return (
            <div>
                {submit === '0' && <Web3Withdraw callGas={(gas) => setGasNum(gas)} callCount={(count) => setCount(count)} callback={(toAddress) => {setToAddress(toAddress)}} usdt={location.state.usdt}  setSubmit={() => {setSubmit('1')}}/>}
                {submit === '1' && <Submit gas={gasNum} address={toAddress} count={Number(count)}  type={'web3'}/>}
            </div>
        )
    }else {
        return (
            <div>
                {submit === '0' && <Container>
                    <FlexBox style={{justifyContent:"flex-start",marginTop:"1.5rem",width:"21.5rem"}}>
                        <BText>{wType === 0?t('Recipient Account'):t('Recipient Wallet')}</BText>
                    </FlexBox>
                    <AddAccount onClick={() => {navigate('/add_collection_account',{state:{wType:wType}})}}>
                        <img style={{width:"1rem",height:"1rem",marginRight:"0.5rem"}} src={require('../../assets/images/withdrawal/add.png')} alt=""/>
                        <BText>{wType === 0?t('Add Recipient Account'):t('Add Recipient Wallet')}</BText>
                    </AddAccount>
                    {
                        <WBox onClick={() => {setModalContent(<CheckAccountModal onRefresh={onRefresh} callback={(item:any) => setCheckAccount(item)} data={accountList} wType={wType}/>);setPosition('bottom');setOpenModal(true)}}>
                            {
                                wType ===0 && <div>
                                    <BText>{checkAccount.bank_name}</BText>
                                    <BText>{checkAccount.bank_account}</BText>
                                    <NameText>{checkAccount.full_name}</NameText>
                                    <NameText>{checkAccount.email}</NameText>
                                </div>
                            }
                            {
                                wType === 1 && <div>
                                    <BText>{checkAccount.account_number}</BText>
                                    <BText>{checkAccount.e_wallet_type}</BText>
                                    <NameText>{checkAccount.full_name}</NameText>
                                    <NameText>{checkAccount.email}</NameText>
                                </div>
                            }
                            <RightArrowIcon src={require('../../assets/images/balance/tx_icon_list_arrow.png')}/>
                        </WBox>
                    }
                    <BalanceBox>
                        <FlexBox style={{justifyContent:"space-between",width:"20rem"}}>
                                <BText>{t('Withdrawal Quantity')}</BText>
                            {/*<GText>This month:{location.state?.balance.MonthlyWithdrawnAmount/100}/{500000}</GText>*/}
                        </FlexBox>
                        <FlexBox style={{justifyContent:"space-between",width:"20rem",borderBottom:"1px solid #EEEEEE"}}>
                            <FlexBox >
                                <BIcon src={require('../../assets/images/balance/tx_icon_yoo_coin.png')}/>
                                <Input placeholder={t('Please enter') + t('Withdrawal Quantity')} value={count} onChange={(e:any) => {setCount(e.target.value)}}/>

                            </FlexBox>
                            <CText onClick={() => {setCount(String(canBalance).split(',').join(''))}}>{t('All')}</CText>

                        </FlexBox >
                        {/*<FlexBox style={{justifyContent:"flex-start",width:"20rem",}}>*/}
                        {/*    <GText>预计到账2,000USD(已扣除手续费:0.1USD)</GText>*/}
                        {/*</FlexBox>*/}
                        <FlexBox style={{justifyContent:"space-between",width:"20rem"}}>
                            <FlexBox style={{justifyContent:"flex-start"}}>
                                <BText>{t('Balance')}</BText>
                                <SIcon  src={require('../../assets/images/balance/tx_icon_yoo_coin.png')}/>
                                <BText style={{marginLeft:"0.25rem",fontWeight:"bold"}}>{canBalance?canBalance:0}</BText>
                            </FlexBox>
                            {/*<CText onClick={() => {setModalContent(<NoticeModal text={'Withdrawal of less than 2,50 yoo coins'}/>);setOpenModal('1');setPosition('center')}}>Quota Description</CText>*/}
                        </FlexBox>
                    </BalanceBox>
                    {/*<FlexBox style={{justifyContent:"flex-start",width:"22.125rem",marginTop:"2.125rem"}}>*/}
                    {/*    <BText>Withdrawal method</BText>*/}
                    {/*</FlexBox>*/}
                    {/*<FlexBox style={{flexDirection:"column",marginTop:"0.25rem"}}>*/}
                    {/*    <CheckItem onClick={() => {setCheckIndex(0)}}>*/}
                    {/*        <Tips>Recommend</Tips>*/}
                    {/*        <FlexBox style={{justifyContent:"flex-start",width: '20.625rem'}}>*/}
                    {/*            <Radio color={checkIndex===0?"#9155FF":"#F0F0F0"}/>*/}
                    {/*            <div style={{marginLeft:'0.75rem',maxWidth:"18rem"}}>*/}
                    {/*                <ItemTitle>Web3 Wallet</ItemTitle>*/}
                    {/*                <ItemText>Exchange the Yoo coins you have obtained for USDT withdrawal to your web3 wallet address</ItemText>*/}
                    {/*            </div>*/}
                    {/*        </FlexBox>*/}
                    {/*        <FlexBox>*/}
                    {/*            <ItemText>{wallets[0].publicAddress}</ItemText>*/}
                    {/*        </FlexBox>*/}
                    {/*    </CheckItem>*/}
                    {/*    <CheckItem onClick={() => {setCheckIndex(1)}}>*/}
                    {/*        <FlexBox  style={{justifyContent:"flex-start",width: '20.625rem'}}>*/}
                    {/*            <Radio color={checkIndex===1?"#9155FF":"#F0F0F0"}/>*/}
                    {/*            <div style={{marginLeft:'0.75rem',maxWidth:"18rem"}}>*/}
                    {/*                <ItemTitle>PayPal</ItemTitle>*/}
                    {/*                <ItemText>Convert the YOO coins you have obtained into US dollars and withdraw them to the Payoneer account you have added</ItemText>*/}
                    {/*            </div>*/}
                    {/*        </FlexBox>*/}
                    {/*        <FlexBox style={{justifyContent:"space-between",width: '20.625rem'}}>*/}
                    {/*            {userInfo.paypal_acc ? <ItemText style={{marginLeft:"0.5rem"}}>{userInfo.paypal_acc}</ItemText>:*/}
                    {/*            <FlexBox onClick={() => navigate('/addWithdrawMethod')} style={{justifyContent:"flex-start"}}>*/}
                    {/*                <AddIcon src={require('../../assets/images/withdrawal/tx_btn_add.png')}/>*/}
                    {/*                <div style={{width:1,height:"0.75rem",backgroundColor:"#99999C",marginLeft:'0.5rem'}}/>*/}
                    {/*                <ItemText style={{marginLeft:"0.5rem"}}>Add withdrawal method</ItemText>*/}
                    {/*            </FlexBox>}*/}
                    {/*            <FlexBox style={{justifyContent:"flex-end",}}>*/}
                    {/*                <LogoIcon src={require('../../assets/images/withdrawal/tx_icon_paypal.png')}/>*/}
                    {/*                {userInfo.paypal_acc && <CText onClick={() => {setModalContent(<YHNoticeModal/>);setOpenModal('1');setPosition('center')}}>update</CText>}*/}
                    {/*            </FlexBox>*/}

                    {/*        </FlexBox>*/}
                    {/*    </CheckItem>*/}
                    {/*</FlexBox>*/}
                    <Btn color={count !== ''?"#5C46FF":"#E2E5E9"} onClick={withdraw}>{t('Withdraw')}</Btn>
                </Container>}
                {submit === '1' && <Submit type={checkIndex===1?'paypal':''} address={wallets[0].publicAddress}  count={Number(count)} gas={gasNum} />}
            </div>
        );
    }

};

export default Withdrawal;
