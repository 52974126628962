import React, {CSSProperties, useEffect, useState} from 'react';
let date:any
let dater:any
const CountDown = ({onClick,style,status}:{onClick:()=>void,style?:CSSProperties,status:boolean}) => {
    const [counts,setCounts] = useState(61)
    const [isSend,setIsSend] = useState(0)
    useEffect(() => {
        return () => {
            clearInterval(dater)
        }
    },[])
    useEffect(() => {
        if (status) {
            startTimer()
            setIsSend(1)
        }else {
            clearInterval(dater)
            setCounts(61)
        }
    },[status])

    function startTimer() {
        setCounts(60)
        if (dater) {
            clearInterval(dater)
        }
        date = new Date().getTime()
        dater = setInterval(() => {
            let newDate = new Date().getTime()
            let count = Math.ceil((newDate-date)/1000)
            if (count > 60) {
                clearInterval(dater);
                setIsSend(2)
            }
            let dValue = 61-count
            setCounts(dValue)
        }, 1000);
    }

    function getText() {
        if (isSend === 0) {
            return  'captcha'
        }else if (isSend === 1) {
            return counts + 's'
        }else if (isSend === 2){
            return 'retry'
        }
    }
    return (
        <div style={{...style,backgroundColor:isSend!==1?"#5C46FF":"#E3E3E3",color:"#FFFFFF"}} onClick={() => {
            if (isSend !== 1){
                onClick()
            }
        }}>
            {getText()}
        </div>
    );
};

export default CountDown;
