import React, {useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Radio, Toast,Dialog} from "antd-mobile";
import {useModal} from "../../../hooks";
import {mailClient} from "../../../services/client";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 21.43rem;
  height: 30.47rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem 1.125rem ;
  
`
const Title = styled.div`
  font-size: 1rem;
  color: #292930;
  font-weight: bold;
  width: 20.5rem;
`
const Tips = styled.div`
  font-size: 1rem;
  color: #99999C;
  width: 20.5rem;
`
const AddAccount = styled.div`
  width: 21.25rem;
  height: 3.06rem;
  background: #FFFFFF;
  border: 0.13px solid #EEEEEE;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
`
const BText = styled.div`
  font-size: 1rem;
  color: #292930;
`
const WBox = styled.div`
  width: 19.5rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.652rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.5rem;
  position: relative;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`
const NameText = styled.div`
  font-size: 1rem;
  color: #99999C;
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  position: fixed;
  bottom: 1rem;
  left: 4.075rem;
  &:hover {
    background: #5C46FF;
  }
`
const DeleteIcon = styled.img`
    width: 1rem;
    height: 1rem;
  position: absolute;
  top:0.5rem;
  right: 1rem;
`
const CheckAccountModal = ({wType,data,onRefresh,callback}) => {
    const navigate =useNavigate()
    const [checkIndex,setCheckIndex] = useState(0)
    const {setOpenModal} = useModal()
    const { t ,i18n} = useTranslation();
    console.log(data)
    function deleteAccount(id){
        mailClient.callApi('financial/DeleteFinancialAccount',{financialId:String(id)}).then((res) => {
            if (res.isSucc){
                onRefresh()
                Toast.show({
                    icon: 'success',
                    content: t('Delete successful'),
                })
            }else {
                Toast.show({
                    icon: 'fail',
                    content:t(t('Delete failed')),
                })
            }
        }).catch(err => {})
    }
    return (
        <Container>
            <Title>{wType===0?t('Select the recipient institution'):t('Select Recipient Wallet')}</Title>
            <Tips>{wType === 0?t('Add up to 5 accounts'):t('Add up to 5 wallets')}</Tips>
            <AddAccount onClick={() => {setOpenModal(false);navigate('/add_collection_account',{state:{wType:wType}});}}>
                <img style={{width:"1rem",height:"1rem",marginRight:"0.5rem"}} src={require('../../../assets/images/withdrawal/add.png')} alt=""/>
                <BText>{wType === 0?t('Add Recipient Account'):t('Add Recipient Wallet')}</BText>
            </AddAccount>

            <div style={{overflowY:'scroll',height:"19rem",marginTop:"1rem"}}>
                {
                    data.map((item,index) => {
                        return (
                            <WBox key={index} onClick={() => {setCheckIndex(index);
                                if (checkIndex === index){

                                }
                            }}>
                                {
                                    wType ===0 && <div>
                                        <BText>{item.bank_name}</BText>
                                        <BText>{item.bank_account}</BText>
                                        <NameText>{item.full_name}</NameText>
                                        <NameText>{item.email}</NameText>
                                    </div>
                                }
                                {
                                    wType === 1 && <div>
                                        <BText>{item.account_number}</BText>
                                        <BText>{item.e_wallet_type}</BText>
                                        <NameText>{item.full_name}</NameText>
                                        <NameText>{item.email}</NameText>
                                    </div>
                                }
                                <Radio checked={checkIndex===index} color={checkIndex===index?"#9155FF":"#F0F0F0"}/>
                                <DeleteIcon onClick={() => {
                                    Dialog.confirm({
                                        content: `${wType === 0?t('Do you want to delete this account?'):t('Do you want to delete this wallet?')}`,
                                        danger:true,
                                        confirmText:<div style={{color:"#F04A4A"}}>{t('Confirm')}</div>,
                                        onConfirm: () => {
                                            deleteAccount(item.id)
                                        },
                                        onCancel:() => {

                                        }
                                    })
                                }} src={require('../../../assets/images/withdrawal/delete.png')}/>
                            </WBox>
                        )
                    })
                }
            </div>
            <Btn color={checkIndex !== -1?"#5C46FF":"#E2E5E9"} onClick={() => {callback(data[checkIndex]);setOpenModal(false)}}>{t('Confirm')}</Btn>
        </Container>
    );
};

export default CheckAccountModal;
