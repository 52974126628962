import { HttpClient } from "tsrpc-browser";
import { serviceProto as mailServiceProto } from "../shared/server_Mail/poto/serviceProto";
import { serviceProto as payServiceProto } from "../shared/server_PayWithdrawal/poto/serviceProto";
import {getToken} from "../utils/token";
import {BaseUrl} from "../utils/baseUrl";


export const mailClient = new HttpClient(mailServiceProto, {
    server: BaseUrl +':4006',
    // Remove this to use binary mode (remove from the server too)
    json: true,
    logger: console,
});
mailClient.flows.preCallApiFlow.push((v) => {v.req.AuthHash = getToken();return v})

export const payClient = new HttpClient(payServiceProto, {
    server:BaseUrl+ ':4005',
    // Remove this to use binary mode (remove from the server too)
    json: true,
    logger: console,
});
payClient.flows.preCallApiFlow.push((v) => {v.req.AuthHash = getToken();return v})
