import React, {useState} from 'react';
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useModal} from "../../../hooks";
import {useTranslation} from "react-i18next";


const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 21.43rem;
  height: 23.47rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem 1.125rem ;
`
const Title = styled.div`
  font-size: 1rem;
  color: #292930;
  font-weight: bold;
`
const ItemBox = styled.div`
  width: 20.5rem;
  height: 3rem;
  background: ${props => props.active ? "#5C46FF":"#FFFFFF"};
  border-top: 1px solid #EEEEEE;
  color: ${props => props.active ? "#FFFFFF":"#000000"};
  border-radius: 0.5rem;
  padding:0.5rem 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 1rem;
`
const ItemText = styled.div`
  font-size: 1rem;
  color: #99999C;
  margin-top: 1rem;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  position: fixed;
  bottom: 1rem;
  left: 4.075rem;
  &:hover {
    background: #5C46FF;
  }
`
const CheckInstitutionModal = ({balance,callback}) => {
    const navigate = useNavigate()
    const {setOpenModal} = useModal()
    const location = useLocation()
    const {wType} = location.state
    const [select,setSelect] = useState(-1)
    const { t ,i18n} = useTranslation();
    const data = [0,1,2,3,4,5,6]
    const banks = [
        'ACEH',
        'ACEH_UUS',
        'AGRIS',
        'AGRONIAGA',
        'ANTARDAERAH',
        'ANZ',
        'ARTHA',
        'ARTOS',
        'BALI',
        'BANTEN',
        'BCA',
        'BCA_SYR',
        'BENGKULU',
        'BJB_SYR',
        'BNI',
        'BNI_SYR',
        'BOC',
        'BRI',
        'BRI_SYR',
        'BSI',
        'BTN',
        'BTN_UUS',
        'BUKOPIN',
        'BUKOPIN_SYR',
        'BUMI_ARTA',
        'CAPITAL',
        'CENTRATAMA',
        'CHINATRUST',
        'CIMB',
        'CIMB_UUS',
        'CITI',
        'COMMONWEALTH',
        'DAERAH_ISTIMEWA',
        'DANAMON',
        'DBS',
        'DINAR_INDONESIA',
        'DKI',
        'GANESHA',
        'HANA',
        'HARDA_INTERNASIONAL',
        'HSBC',
        'ICBC',
        'INA_PERDANA',
        'INDEX_SELINDO',
        'INDIA',
        'JABAR',
        'JAMBI',
        'JASA_JAKARTA',
        'JAWA_TENGAH',
        'JAWA_TIMUR',
        'JTRUST',
        'KALIMANTAN_BARAT',
        'KALIMANTAN_SELATAN',
        'KALIMANTAN_TENGAH',
        'KALIMANTAN_TIMUR',
        'KESEJAHTERAAN',
        'KS',
        'LAMPUNG',
        'MALUKU',
        'MANDIRI',
        'MANDIRI_SYR',
        'MANDIRI_TASPEN',
        'MASB',
        'MASPION',
        'MAYAPADA',
        'MAYBANK',
        'MAYORA',
        'MEGA',
        'MEGA_SYR',
        'MESTIKA_DHARMA',
        'MNC_INTERNASIONAL',
        'MUAMALAT',
        'MULTICOR',
        'NATIONALNOBU',
        'NUSA_TENGGARA_BARAT',
        'NUSA_TENGGARA_TIMUR',
        'NUSANTARA_PARAHYANGAN',
        'OCBC',
        'PANIN',
        'PANIN_SYR',
        'PAPUA',
        'PERMATA',
        'PERMATA_UUS',
        'QNB_INDONESIA',
        'RABOBANK',
        'RIAU_DAN_KEPRI',
        'ROYAL',
        'SAHABAT_SAMPOERNA',
        'SBI_INDONESIA',
        'SCB',
        'SHINHAN',
        'SINARMAS',
        'SULAWESI',
        'SULSELBAR',
        'SULTRA',
        'SULUT',
        'SUMATERA_BARAT_UUS',
        'SUMSEL_DAN_BABEL',
        'SUMUT',
        'TABUNGAN_PENSIUNAN_NASIONAL',
        'TOKYO',
        'UOB',
        'VICTORIA',
        'VICTORIA_SYR',
        'WOORI',
        'YUDHA_BHAKTI',
        'BTPN',
        'BTPN_SYARIAH',
        'SEABANK'
    ];
    const wallets = [
        'OVO',
        'GOPAY',
        'SHOPEEPAY',
        'DANA',
    ]
    function getIns() {
        if (wType === 0) {
            return banks
        } else {
            return wallets
        }
    }
    return (
        <Container>
            <Title>{wType===0?t('Select the recipient institution'):t('Select Recipient Wallet')}</Title>
            <div style={{overflowY:'scroll',height:"18rem",marginTop:"1rem"}}>
                {
                    getIns().map((item,index) => {
                        return (
                            <ItemBox active={select === index} onClick={() => {setSelect(index)}}>
                                {item}
                            </ItemBox>
                        )
                    })
                }
            </div>
            <Btn color={select !== -1?"#5C46FF":"#E2E5E9"} onClick={() => {callback(wType===0?banks[select]:wallets[select]);setOpenModal(false)}}>{t('Confirm')}</Btn>

        </Container>
    );
};

export default CheckInstitutionModal;
