import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {useWindowSize} from "../../../useWindowSize";
import {useNavigate} from "react-router-dom";
import {getFeedbackList} from "../../../services/feedback";
import {getToken} from "../../../utils/token";
import md5 from "js-md5";
import {useTranslation} from "react-i18next";

const Container = styled.div`
      padding: 0 1rem;
      background-color: #FFFFFF;
`

const Item = styled.div`
    padding: 0.75rem;
    color: #000000;
    font-size: 1rem;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 20.5rem;
      border-radius: 0.75rem;
      position: relative;
      height: 2.25rem;
      margin-top: 1rem;
  background-color: #F5F5F5;
  
`

const FbHistory = () => {
    const {height} = useWindowSize()
    const navigate = useNavigate()
    const [list,setList] = useState([])
    const RoleId = localStorage.getItem('roleId')
    const ShortId = localStorage.getItem('shortId')
    const { t } = useTranslation();
    const Token = getToken()
    const time = new Date().getTime()

    const sign = RoleId + Token + time
    const getList = () => {
        getFeedbackList({page:0,limit:10,RId:RoleId,Token:Token,Timestamp:time,Sign:md5(sign)}).then(res => {
            if (res.data.code === 0) {
                setList(res.data.data._v)
            }
        })
    }
    useEffect(() => {
        getList()
    },[])
    return (
        <Container style={{height:height,}}>
            {list.map((item,index) => {
                return (
                    <div key={index} style={{position:'relative',height:"3.5rem"}}>
                        {item.IsReply &&
                        <div style={{position:"absolute",top:"-0.5rem",left:0,
                            zIndex:3,
                            backgroundColor:"#5847F5",color:'#FFFFFF',padding:"0.15rem",
                            fontSize:"0.75rem",
                            borderRadius:"0.5rem"}}>{t('Replied')}</div>}
                        <Item onClick={() => navigate('/fb_detail/' + item.FId)} >
                            {item.Content}
                        </Item>
                    </div>

                )
            })}

        </Container>
    );
};

export default FbHistory;
