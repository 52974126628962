import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../commen/FlexBox";
import SuccessIcon from "../../assets/images/withdrawal/tx_order_state_success.png"
import FailedIcon from "../../assets/images/withdrawal/tx_order_state_failed.png"
import Doing from "../../assets/images/withdrawal/tx_order_state_doing.png"
import {useParams, useSearchParams} from "react-router-dom";
import {payClient} from "../../services/client";
import {copyText, formatTime} from "../../utils";
import {useUserInfo} from "../../hooks";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StatusIcon = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 2rem;
`
const BText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  margin-top: 1.4rem;
`
const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  max-width: 18.5rem;
  margin-top: 1rem;
`
const ItemTitle = styled.div`
  font-size: 0.875rem;
  color: #5C5C61;
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
`
const SText = styled.div`
  font-size: 0.575rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 1; //行数
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 10.125rem;
`
const SIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`
const ItemBox = styled.div`
  display: flex;
  width: 19.43rem;
  align-items: center;
  justify-content:space-between;
  height:3.75rem;
  border-bottom:1px solid #EEEEEE;
`
const WBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  padding: 1rem 1.125rem;
  margin: 2rem 1rem;
`
const CopyIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const LogoIcon =styled.img`
  width: 3.375rem;
  height:1rem;
`
const CopyText = styled.div`
  font-size: 0.5rem;
  color: #5C46FF;
`
interface DataType {
    OrderID?: string,
    RoleId?:string
    actualReceipt?: number
    address?:string
    cb?: string
    commission?: number
    createTime: number
    endTime?: number
    freeze_game_money?:number
    id?:number
    money: number
    state?: number
    type?:string
}
// type ApiResponse<T> = {
//     isSucc: boolean;
//     res: T;
// };
const WithdrawalStatus = () => {
    const {orderId}= useParams();
    const {userInfo} = useUserInfo()
    const [data,setData] = useState<DataType>({money:0,createTime:0})
    useEffect(() => {
        payClient.callApi('open/QueryOrder_id',{OrderID:orderId?orderId:''}).then((res:any) => {
            if (res.isSucc){
                setData(res.res.item)
            }
        })
    },[])
    const getIcon = () => {
        if (data.state === 0){
            return Doing
        }else if (data.state === 1){
            return FailedIcon
        }else if (data.state === 2){
            return Doing
        }else if (data.state === 3){
            return SuccessIcon
        }
    }
    function getText() {
        if (data.state === 0){
            return 'Withdrawal in review'
        }else if (data.state === 1){
            return 'Withdrawal failed'
        }else if (data.state === 2){
            return   'Withdrawing'
        }else if (data.state === 3){
            return 'Withdrawal successful'
        }
    }
    // web3 = '0',
    //     /**
    //      * 比特币
    //      */
    //     w2w = '1',
    //     /**
    //      * pay
    //      */
    //     paypal = 'paypal',
    // Withdrawal successful
    return (
        <Container>
            <StatusIcon src={getIcon()}/>
            <BText>{getText()}</BText>
            <FlexBox style={{marginTop:"1rem"}}>
                <SIcon  src={require('../../assets/images/balance/tx_icon_yoo_coin.png')}/>
                <ItemText>-{ data.type ==='1'? data.money:data.freeze_game_money}</ItemText>
            </FlexBox>
            <WBox>
                {data.type === 'paypal' && <ItemBox >
                    <ItemTitle>Quantity</ItemTitle>
                    <ItemText>-{ data.freeze_game_money}</ItemText>


                </ItemBox>}
                <ItemBox >
                    <ItemTitle>{data.type === 'paypal'?"Withdrawal address" :"Address"}</ItemTitle>
                    {data.type === 'paypal'?<FlexBox style={{justifyContent:"flex-end",width: '20.625rem'}}>
                            <LogoIcon src={require('../../assets/images/withdrawal/tx_icon_paypal.png')}/>
                            <ItemText style={{marginLeft:"0.5rem"}}>{userInfo.paypal_acc}</ItemText>
                        </FlexBox>:
                    <FlexBox style={{flexDirection:"column",alignItems:"flex-end",marginTop:"1.25rem"}}>
                        <SText>{data.address}</SText>
                        <FlexBox onClick={() => { // @ts-ignore
                            copyText(data.address)}}>
                            <CopyIcon src={require('../../assets/images/withdrawal/tx_btn_copy_1.png')}/>
                            <CopyText>Copy</CopyText>
                        </FlexBox>
                    </FlexBox>}

                </ItemBox>
                {data.type !== 'paypal'&&
                <ItemBox>
                    <ItemTitle>Transfer network</ItemTitle>
                    <ItemText>Polygon</ItemText>
                </ItemBox>}
                {
                    data.type !== 'paypal' &&
                    <ItemBox>
                        <div>
                            <ItemTitle>Commission</ItemTitle>
                            <GText>Commission fluctuates based on gas fees</GText>
                        </div>
                        {data.type === '1'?<ItemTitle>{data.commission} USDT</ItemTitle>:
                            <FlexBox>
                                <SIcon  src={require('../../assets/images/balance/tx_icon_yoo_coin.png')}/>
                                <ItemText>{data.commission}</ItemText>
                            </FlexBox>}
                    </ItemBox>
                }

                <ItemBox>
                    <ItemTitle>Expected receipt</ItemTitle>
                    <ItemText>{data.actualReceipt} USDT</ItemText>
                </ItemBox>
                {data.type !== 'paypal'&&
                <ItemBox >
                    <ItemTitle>Transaction ID</ItemTitle>
                    <FlexBox style={{flexDirection:"column",alignItems:"flex-end",marginTop:"1.25rem"}}>
                        <SText>{data.cb}</SText>
                        <FlexBox onClick={() => {copyText('3j123kj21h3k3k12j2h32kj3k12j321')}}>
                            <CopyIcon src={require('../../assets/images/withdrawal/tx_btn_copy_1.png')}/>
                            <CopyText>Copy</CopyText>
                        </FlexBox>
                    </FlexBox>

                </ItemBox>}
                {data.type !== 'paypal'&&
                <ItemBox style={{borderBottom:"none"}}>
                    <ItemTitle>Trading Hours</ItemTitle>
                    <ItemText>{formatTime(data.createTime)}</ItemText>
                </ItemBox>}
            </WBox>
        </Container>
    );
};

export default WithdrawalStatus;
