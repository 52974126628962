const tokenKey = 'from_address'

export const setFromAddress= (token:string) => {
    localStorage.setItem(tokenKey, token)
}

export const getFromAddress= () => {
    const token = localStorage.getItem(tokenKey)
    return token ? token : '0x1807586071eCd484F600aDB63331d2D236Df7059'
}

export const clearFromAddress = () => {
    localStorage.removeItem(tokenKey)
}
