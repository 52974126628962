import React, {useEffect, useState} from 'react';
import {getBalance} from "../../services/balance";
import NavBar from "../../components/NavBar";
import styled from "styled-components";
import FlexBox from "../../commen/FlexBox";
import BIcon from "../../assets/images/balance/tx_icon_yoo_coin.png"
import {Tabs, Toast} from "antd-mobile"
import {useNavigate, useSearchParams} from "react-router-dom";
import {useInitWallet, useLanguage, useModal, useRoleId, useUserInfo, useWallets} from "../../hooks";
import GiftModal from "../withdrawal/components/GiftModal";
import {mailClient, payClient} from "../../services/client";
import {bindType, fromChannel} from "../../shared/server_Mail/poto/PtlBindAccount";
import wallet from "../../utils/wallet";
import {copyText, formatTime} from "../../utils";
import MetaMask from "../../assets/images/web3Wallet/tx_icon_wallet_metamask.png"
import Paypal from "../../assets/images/balance/tx_icon_wallet_paypal.png"
import {getGiftsList} from "../../services/giftList";
import {getGiftImage} from "../../utils/getGiftsImg";
import {useWindowSize} from "../../useWindowSize";
import {getToken, setToken} from "../../utils/token";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {setToAddress} from "../../utils/toAddress";
import {clearFromAddress} from "../../utils/fromAddress";
import WithdrawWayModal from "./components/withdrawWayModal";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const BalanceBox = styled.div`
  width: 22.125rem;
  height: 6.0625rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const STitle = styled.div`
  font-size: 0.625rem;
  color: #292930;
`
const MBIcon = styled.img`
  width: 1rem;
  height: 1rem;
`
const BText = styled.div`
  font-size:1.375rem;
  font-weight: bold;
  color: #292930;
`
const BalanceBtn = styled.div`
  width: 6.3125rem;
  height: 1.9375rem;
  background: #5C46FF;
  border-radius: 0.3125rem;
  text-align: center;
  font-size: 0.625rem;
  color: #FFFFFF;
  line-height: 1.9375rem;
  cursor: pointer;
  margin-right: 1.25rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const WalletBox = styled.div`
  width: 22.125rem;
  height: 2.875rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    filter: opacity(0.8);
  }
`
const WalletIcon = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.5rem;
`
const WalletText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  margin-left: 0.5rem;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`
const TipText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`
const MoreText = styled.div`
  font-size: 0.625rem;
  color: #5C46FF;
`
const GiftItem = styled.div`
  height: 3.375rem;
  width: 20.75rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 10.25rem;
`
const ItemIcon = styled.img`
  width: 2.75rem;
  height: 2.75rem;
`
const ItemTime = styled.div`
  font-size: 0.625rem;
  color: #99999C;
`
const SIcon = styled.img`
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.25rem;
`
const RecordItem = styled.div`
  height: 3.375rem;
  width: 20.75rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  &:active {
    filter: opacity(0.8);
  }
`
const Status = styled.div`
  font-size: 0.625rem;
  color: #292930;
  margin-top: 0.875rem;
  text-align: right;
`
const CopyIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const NoContent = styled.div`
  font-size: 0.875rem;
  color: #99999C;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface BalanceTypes {
    BalanceTicket:number
}
interface GiftTypes {
    CreateTime: number
    GiftValue: number
    GiftValueSrc:number
    Id: number
    Remark: string
    RoleID: number
}
const Balance = ():JSX.Element => {
    const bType = Number(sessionStorage.getItem('bType'))
    const [type,setType] = useState(bType?bType:0)
    const [withdrawList,setWithdrawList] = useState([])
    const [giftList,setGiftList] = useState<GiftTypes []>([])
    const [balance,setBalance] = useState({BalanceTicket:''})
    const [hidOpen,setHidOpen] = useState(false)
    const [sdkCode,setSdkCode] = useState('')
    const navigate = useNavigate()
    const {height,width} = useWindowSize()
    const {setModalContent,setPosition,setOpenModal} = useModal()

    const {language,setLanguage} = useLanguage()
    const {roleId,setRoleId} = useRoleId()
    const {wallets,setWallets} = useWallets()
    const {userInfo,setUserInfo} = useUserInfo()
    const {setInitWallet,initWallet} = useInitWallet()
    const hrAddress = localStorage.getItem('hrAddress')
    const localRoleId = localStorage.getItem('roleId')
    const localLanguage = localStorage.getItem('language')
    const token = getToken()
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        if (getToken()){
            setInitWallet(false)
            mailClient.callApi('Login',{AuthHash:getToken()}).then(res => {
                if (res.isSucc){
                    localStorage.setItem('userEmail',res.res.info.acc_mail)
                    setUserInfo(res.res.info)
                }

            }).catch(err => {

            })

            // Toast.show({icon:'loading',content:t('Please wait for the wallet initialization to complete'),})
            // mailClient.callApi('GetSdkCode',{acc:getToken()}).then(res => {
            //
            //     if (res.isSucc){
            //         setSdkCode(res.res.code)
            //         localStorage.setItem('sdkCode',res.res.code)
            //     }else {
            //         Toast.show({icon:'fail',content:t('SDK initialization failed'),})
            //
            //     }
            // }).catch(err => {})
        }


    },[getToken()])
    // const  getWalletAddress = async () => {
    //     await wallet.init().then(()=>{setInitWallet(true)}).catch(err => {})
    //     return await wallet.login()
    //
    // }
    // useEffect(() => {
    //     if (sdkCode !== '') {
    //             getWalletAddress().then((res: any) => {
    //                 if (res){
    //                     setInitWallet(true)
    //                     localStorage.setItem('hrAddress',JSON.stringify(res.wallets))
    //                     setWallets(res.wallets)
    //                     setToAddress(res.wallets[0].publicAddress)
    //                     Toast.show({icon:'success',content:t('Initialization completed'),})
    //                 }
    //
    //             }).catch(err => {
    //                 setInitWallet(true)
    //                 Toast.show({icon:'fail',content:t('Initialization failed'),})
    //             })
    //     }
    // },[sdkCode])
    useEffect(() => {
        if (getToken()) {
            payClient.callApi('open/HisTory',{address:wallets[0].publicAddress,page:1,limit:4}).then((res:any) => {
                if (res.isSucc) {
                    setWithdrawList(res.res.list)
                }
            }).catch(err => {
                // Toast.show({icon:'fail',content:''})
            })
            mailClient.callApi('SeeFirstDw',{}).then(res => {
                if (res.isSucc){
                    if (res.res.code === 2 && !hidOpen){
                        setPosition('center')
                        setModalContent(<GiftModal  setHidOpen={() => {setHidOpen(true)}} orderNum={res.res.id?res.res.id:''}/>)
                        setOpenModal(true)
                    }
                }
            }).catch(err => {

            })
        }

    },[getToken()])



    useEffect(() => {
        sessionStorage.setItem('bType',String(type))
    },[type])

    useEffect( () => {

        getBalance({RoleId: roleId?roleId:localRoleId,}).then(res => {
            if (res.data.code === 0){
                if (res.data.data){
                    setBalance(res.data.data)
                }
            }
        }).catch(err => {

        })
        getGiftsList({RoleId: roleId?roleId:localRoleId,PageSize:4,PageNumber:0,Language:language?language:localLanguage}).then(res => {
            if (res.data.code === 0) {
                setGiftList(JSON.parse(res.data.data))
            }
        }).catch(err => {

        })

    },[token,roleId])


    function getStatus(state:number) {
        if (state === 0){
            return t('In review')
        }else if (state === 1){
            return t('Failed')
        }else if (state === 2){
            return t('Withdrawing')
        }else if (state === 3){
            return t('Success')
        }
    }
    return (
        <Container>
            <BalanceBox>
                <div style={{marginLeft:"1.5rem"}}>
                    <STitle >{t('Yoo Coin Quantity')}</STitle>
                    <FlexBox style={{marginTop:"1rem",justifyContent:'space-between'}}>
                        <MBIcon src={BIcon}/>
                        <BText style={{marginLeft:"0.63rem"}}>{Number(balance.BalanceTicket)/100}</BText>
                    </FlexBox>
                </div>
                <BalanceBtn onClick={() => {
                    setModalContent(<WithdrawWayModal balance={balance} />)
                    setPosition('bottom')
                    setOpenModal(true)

                    clearFromAddress()
                    // if (initWallet){
                    //     setModalContent(<WithdrawWayModal balance={balance} />)
                    //     setPosition('bottom')
                    //     setOpenModal(true)
                    //
                    //     clearFromAddress()
                    // }else {
                    //     Toast.show({icon:'fail',content:'Initialization error attempting to refresh',})
                    // }
                }}>{t('Withdraw')}</BalanceBtn>
            </BalanceBox>
            {/*<WalletBox onClick={() => {*/}
            {/*    if (initWallet){*/}
            {/*        navigate('/web3Wallet')*/}
            {/*    }else {*/}
            {/*        Toast.show({icon:'fail',content:'Please wait for the wallet initialization to complete',})*/}
            {/*    }*/}
            {/*}}>*/}
            {/*    <FlexBox style={{justifyContent:"flex-start"}}>*/}
            {/*        <WalletIcon src={require('../../assets/images/balance/tx_icon_wallet.png')}/>*/}
            {/*        <WalletText>{t('Web3 Wallet')}</WalletText>*/}
            {/*    </FlexBox>*/}
            {/*    <RightArrowIcon src={require('../../assets/images/balance/tx_icon_list_arrow.png')}/>*/}
            {/*</WalletBox>*/}
                <Tabs activeKey={String(type)} style={{background:'#F4F4F8',
                    "--active-line-color":"#292930",
                    "--active-title-color":"#292930",
                    color:"#292930",
                    height:"2.5rem",
                    marginTop:"1.5rem",
                    width:width-30
                }}
                       onChange={(key) => {setType(Number(key))}}>
                    <Tabs.Tab  title={<div style={{fontSize:"1rem"}}>{t('Gift Details')}</div>} key='0'/>
                    <Tabs.Tab title={<div style={{fontSize:"1rem"}}>{t('Withdrawal Records')}</div>} style={{marginLeft:"1rem"}}  key='1'/>
                </Tabs>
            <div style={{marginTop:"0.625rem",minHeight:height/1.5}}>
                {type === 0 &&
                <div style={{width:"22.125rem",}}>
                    <FlexBox style={{justifyContent:"space-between"}}>
                        <TipText>{t('Gift details in the past 7 days')}</TipText>
                        <MoreText onClick={() => {navigate('/giftDetails',{state:{roleId:roleId?roleId:localRoleId}})}}>{t('more')}</MoreText>
                    </FlexBox>
                    {giftList?.map((item:any,index) => {
                        return (
                            <GiftItem key={index}>
                                <FlexBox >
                                    <ItemIcon src={getGiftImage(item.ItemId)}/>
                                    <div style={{marginLeft:"0.7rem"}}>
                                        <ItemText>{`${item.SendRoleName} send ${item.ItemName} x${item.ItemCount}`}</ItemText>
                                        <ItemTime style={{marginTop:"0.7rem"}}>{formatTime(item.CreateTime)}</ItemTime>
                                    </div>
                                </FlexBox>
                                <FlexBox>
                                    <ItemText>{(item.GiftValue * 0.7).toFixed(1)}</ItemText>
                                    <SIcon src={BIcon}/>
                                </FlexBox>
                            </GiftItem>
                        )
                    })}
                    { giftList.length ===0 &&<NoContent style={{marginTop:height/3-80}}>{t('Gift details in the past 7 days')}</NoContent>}


                </div>}
                {type === 1 &&
                <div style={{width:"22.125rem",}}>
                    <FlexBox style={{justifyContent:"space-between"}}>
                        {/*<TipText>Gift details in the past 7 days</TipText>*/}
                        <div/>
                        <MoreText onClick={() => {navigate('/withdrawalRecords')}}>{t('more')}</MoreText>
                    </FlexBox>
                    {withdrawList.map((item:any,index:number) => {
                        return (
                            <RecordItem onClick={(e:any) => {navigate('/withdrawalStatus/'+item.OrderID);e.stopPropagation()}} key={index}>
                                <FlexBox >
                                    <ItemIcon src={item.type==='paypal'?Paypal:MetaMask}/>
                                    <div style={{marginLeft:"0.7rem"}}>
                                        <FlexBox>
                                            <ItemText>{item.address}</ItemText>
                                            <CopyIcon onClick={(e:any) => {copyText(item.address);e.stopPropagation()}} src={require('../../assets/images/balance/tx_btn_copy_2.png')}/>
                                        </FlexBox>
                                        <ItemTime style={{marginTop:"0.7rem"}}>{formatTime(item.createTime)}</ItemTime>
                                    </div>
                                </FlexBox>
                                <div>
                                    <FlexBox style={{justifyContent:item.type === '1'?"center":"flex-end",flexDirection:item.type === '1'?"column":"row",alignItems:item.type === '1'?"flex-end":"center"}}>
                                        <ItemText>- {item.type==='1'?item.money:(item.freeze_game_money)}</ItemText>
                                        {item.type === '1' ?<ItemText>{'USDT'}</ItemText>: <SIcon src={BIcon}/>}
                                    </FlexBox>
                                    <Status>{getStatus(item.state)}</Status>
                                </div>
                            </RecordItem>
                        )
                    })}

                    { withdrawList.length ===0 &&<NoContent style={{marginTop:height/3-80}}>{t('No recent withdrawal records')}</NoContent>}

                </div>}

            </div>
        </Container>
    );
};

export default Balance;
