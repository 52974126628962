import Gift201 from '../assets/images/gifts/item_gift_201.png'
import Gift202 from '../assets/images/gifts/item_gift_202.png'
import Gift203 from '../assets/images/gifts/item_gift_203.png'
import Gift204 from '../assets/images/gifts/item_gift_204.png'
import Gift205 from '../assets/images/gifts/item_gift_205.png'
import Gift206 from '../assets/images/gifts/item_gift_206.png'
import Gift207 from '../assets/images/gifts/item_gift_207.png'
import Gift208 from '../assets/images/gifts/item_gift_208.png'
import Gift209 from '../assets/images/gifts/item_gift_209.png'
import Gift210 from '../assets/images/gifts/item_gift_210.png'
import Gift211 from '../assets/images/gifts/item_gift_211.png'
import Gift212 from '../assets/images/gifts/item_gift_212.png'
import Gift213 from '../assets/images/gifts/item_gift_213.png'
import Gift214 from '../assets/images/gifts/item_gift_214.png'
import Gift215 from '../assets/images/gifts/item_gift_215.png'

export function getGiftImage(id:number){
    switch (id) {
        case 201:
            return Gift201
        case 202:
            return Gift202
        case 203:
            return Gift203
        case 204:
            return Gift204
        case 205:
            return Gift205
        case 206:
            return Gift206
        case 207:
            return Gift207
        case 208:
            return Gift208
        case 209:
            return Gift209
        case 210:
            return Gift210
        case 211:
            return Gift211
        case 212:
            return Gift212
        case 213:
            return Gift213
        case 214:
            return Gift214
        case 215:
            return Gift215

    }
}
