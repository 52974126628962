const tokenKey = 'party_token'

export const setToken = (token:string) => {
  localStorage.setItem(tokenKey, token)
}

export const getToken = () => {
  const token = localStorage.getItem(tokenKey)
  return token ? token : ''
}

export const clearToken = () => {
  localStorage.removeItem(tokenKey)
}
