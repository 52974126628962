import { initSDK, setSdkExpiredListener, AoasWallet, utils, AoasAuth } from 'aoas-web-sdk'
import {mailClient} from "../services/client";
import {useUserInfo} from "../hooks";
import {getToAddress} from "./toAddress";
import {BaseUrl} from "./baseUrl";
// AoasWallet
// 0xca216d4971268a4699504018709de5ad27cd6d2e map地址
// 0x1807586071eCd484F600aDB63331d2D236Df7059 钱包地址
const fromAddress = '0x1807586071eCd484F600aDB63331d2D236Df7059';

// 0x4daf14c6ce780595040b5a40e4fcece066228848 map地址
// 0x2a15F9ff5b8AbdE333307e82f43dc0Ecb423a2Ce 钱包地址
// 0x1807586071eCd484F600aDB63331d2D236Df7059 我的钱包地址
const toAddress = getToAddress();

class wallet {
    ok: boolean = false;
    constructor() {

    }



    async init() {
        const sdkCode=localStorage.getItem('sdkCode')
        const userEmail = localStorage.getItem('userEmail')
        console.log(sdkCode,userEmail)

        setSdkExpiredListener(() => {
            console.log('token 过期')
        })

        return new Promise((resolve, reject) => {
            initSDK({
                // 1gumqkqhb.le
                //1h2p9l1sl.8uo
                // 1h2pt73dg.ptg
                userId: userEmail?userEmail:'',
                appId: '4740537179522990081',
                appKey: 'P4TE8wysm0B76OqFn2J1ND3jzpcuCKAg',
                code: sdkCode?sdkCode:'',
                bundleId:BaseUrl+':9527',
                isTestEnv: true,
                listener: {
                    onSuccess() { // 初始化成功后的回调
                        // showToast.text('SDK初始化成功')
                        console.log('SDK初始化成功')
                        resolve(true)
                    },
                    onFailure(err) { // 初始化失败的回调
                        console.log('err', err);
                        reject(err)
                    }
                }
            })
        })

    }
    async login() {
        let self = this;
        return new Promise((resolve, reject) => {
            AoasAuth.login({ chainType: "evm_chain" }, {
                onSuccess(res: any) {
                    self.ok = true;
                    console.log('login::', res)
                    resolve(res);
                },
                onFailure(errCode: any) {
                    console.log('loginError:::', errCode)
                    reject(errCode)
                }
            })
        })

    }
     async getGasPrice(): Promise<string> {
        return new Promise((resolve, reject) => {
            // 获取gas数
            AoasWallet.getGasPrice({
                chainId: '80001',
                chainType: 'evm_chain'
            }, {
                onSuccess: (data) => {
                    console.log('getGasPrice', data)
                    resolve(data.result)
                },
                onFailure: (err) => {
                    console.log('fail', err)
                    reject(err)
                }
            })
        })
    }
    /**
     * 获取代币精度
     */
     async getTokenList(): Promise<[]> {
        return new Promise((resolve, reject) => {
            AoasWallet.getTokenList({
                chainId: '80001',
                chainType: 'evm_chain'
            }, {
                onSuccess: (data) => {
                    if (data.retCode === 0) {
                        resolve(data.data)
                    }

                },
                onFailure: (err) => {
                    console.log('fail', err)
                    reject(err)
                }
            }
            )
        })
    }
    async checkAddress(address:string):Promise<string>  {
         return new Promise((resolve,reject) => {
             AoasWallet.checkAddress({
                 chainId: '80001',
                 chainType: 'evm_chain',
                 address: address, // 钱包地址
             }, {
                 onSuccess: (data) => {
                     console.log('checkAddress:', data.result)
                     resolve(data.result)
                 },
                 onFailure: (err) => {
                     console.log('fail', err);
                     reject(err)
                 }
             })
         })

    }
    /**
     * 查询余额
     */
    async getBalance() {
        // @ts-ignore
        let data = await this.getTokenList()
        let addressArr:any[] = []
        data.forEach((item:any) => addressArr.push(item.address))
        return new Promise((resolve,reject) => {
            AoasWallet.getBalance({
                chainId: '80001',
                chainType: 'evm_chain',
                tokens: addressArr // 查询的代币地址列表
            }, {
                onSuccess: (data) => {
                    console.log('getBalance:', data.result)
                    resolve(data.result)
                },
                onFailure: (err) => {
                    console.log('fail:getBalance', err);
                    reject(err)

                }
            })
        })

    }
    /**
     * 查询代币比例
     */
    async getRate(binAddress:any) {

        return new Promise((resolve,reject) => {
            AoasWallet.getEthExchangeRate({
                chainId: '80001',
                chainType: 'evm_chain',
                tokens:[binAddress]// 查询的代币地址列表
            }, {
                onSuccess: (data) => {
                    console.log('getRate:', data)
                    resolve(data.data)
                },
                onFailure: (err) => {
                    console.log('fail:getRate', err);
                    reject(err)

                }
            })
        })

    }

    /**
     * 获取预估gas数
     */
    async getGasLimit(value: string): Promise<string> {
        return new Promise((resolve, reject) => {
            AoasWallet.getGasLimit({
                chainId: '80001',
                from: fromAddress,
                to: toAddress,
                value: value,
                data: '',
                chainType: 'evm_chain'
            }, {
                onSuccess: (data) => {
                    console.log('预估gas:', data.result)
                    resolve(data.result)
                },
                onFailure: (err) => {
                    console.log('fail', err);
                    reject(err)
                }
            })
        })

    }
    /**
     * 发起交易
     */
    async sendTransaction(_val: number=0.001,toAddress:string): Promise<any> {
        _val = 0.001;
        AoasWallet.getWalletNetworkList({
            onSuccess: (data) => {
                console.log('区块链列表:', data)
            },
            onFailure: (err) => {
                console.log('fail', err)
            }
        })
        let gasPrice = await this.getGasPrice();
        // @ts-ignore
        let data = await this.getTokenList();
        // @ts-ignore
        let decimals = data[0].decimals
        let getGas = await this.getGasLimit(utils.bigTrans16(_val,decimals ));
        let commission = parseInt(getGas, 16) * parseInt(gasPrice, 16) / Math.pow(10, 18);
        console.log('手续费', commission);

        let val = utils.bigTrans16(_val - commission, decimals)

        return new Promise((resolve,reject) => {
            console.log('实际转账:', val, '手续费:', commission)


            AoasWallet.sendTransaction(
                {
                    chainId: '80001',
                    chainType: 'evm_chain',
                    to: toAddress, // 接收方地址  接收方钱包地址
                    gasPrice: gasPrice, // gas price 可从getGasPrice获取
                    gasLimit: getGas, //  可从getGasLimit获取
                    value: val // 交易value 16进制  可用utils工具类的bigTrans16转换方法将10进制数进行转换， utils.bigTrans16(10进制原始值, token.decimals)
                }, {
                    onSuccess: (data) => {
                        console.log('sendTransaction:', data)
                        resolve({hax:data.result,commission:commission,val:_val - commission})
                    },
                    onFailure: (err) => {
                        console.log('fail:sendTransaction:', err);
                        reject(err)
                    }
                }
            )
        })

        // {
        //     chainId: string 区块链id
        //     chainType: string  区块链类型NFT
        //     jsonrpc?: string
        //     to: string // 接收方地址  接收方钱包地址
        //     gasPrice: string // gas price 可从getGasPrice获取
        //     gasLimit: string //  可从getGasLimit获取
        //     value: string // 交易value 16进制  可用utils工具类的bigTrans16转换方法将10进制数进行转换， utils.bigTrans16(10进制原始值, token.decimals)
        //     nonce?: string // 交易nonce
        //     data?: string // 交易数据
        //   }
    }
}
export default new wallet();
