import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {mailClient} from "../../../services/client";
import {Toast} from "antd-mobile";
import {useModal} from "../../../hooks";

const Container = styled.div`
  width: 19rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
  padding: 1rem;
`
const WBox = styled.div`
  width: 20.125rem;
  height: 2.0625rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.652rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.875rem;
`
const Title = styled.div`
  font-size: 0.875rem;
  color: #232323;
`

const Input = styled.input`
  width: 19rem;
  height: 2rem;
  outline: none;
  border: none;
  color: #232323;
  background: #F3F3F3;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  text-indent: 0.5rem;
  font-size: 1rem;
`
const Btn = styled.div`
  width: 6rem;
  height: 2rem;
  background:${props => props.color};
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #FFFFFF;
  line-height: 2rem;
  text-align: center;
  margin-top: 1rem;
`
const SetPwModal = ({openWithModal}:{openWithModal:() => void}) => {
    const [value,setValue] = useState({password:'',cPassword:''})
    const {setOpenModal} = useModal()
    const submit = () => {
        if (value.password === ''){
            Toast.show({icon:'fail',content:'Please set your password'})
            return;
        }
        if (value.cPassword === ''){
            Toast.show({icon:'fail',content:'Please enter your password again'})
            return;
        }
        if (value.cPassword !== value.password){
            Toast.show({icon:'fail',content:'The passwords entered do not match'})
            return;
        }
        mailClient.callApi('pay/SetPayPsw',{psw:value.password}).then(res => {
            if (res.isSucc){
                Toast.show({icon:'success',content:'Set successful'})
                setOpenModal(false)
                openWithModal()
            }
        }).catch(err => {

        })
    }
    return (
        <Container>
            <Title>Set your password</Title>
              <Input maxLength={6} value={value.password} onChange={(e:any) => setValue({...value,password: e.target.value})} type={'password'} placeholder={'Please enter your password'} />
            <Title style={{marginTop:'1rem'}}>Confirm your password</Title>
             <Input maxLength={6} value={value.cPassword} onChange={(e:any) => setValue({...value,cPassword: e.target.value})} type={'password'} placeholder={'Please enter your password again'} />
            <FlexBox>
                <Btn color={value.cPassword.length > 5 ? "#5C46FF":"#E2E5E9"} onClick={submit}>Confirm</Btn>
            </FlexBox>

        </Container>
    );
};

export default SetPwModal;
