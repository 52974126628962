import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useSearchParams} from 'react-router-dom'
import NavBar from "../../components/NavBar";
import wallet from "../../utils/wallet";
import WModal from "../../commen/WModal";
import Routes from "../../router";
import {PContext} from "../../context";
import {mailClient} from "../../services/client";
import {useLanguage, useRoleId} from "../../hooks";
import {getToken, setToken} from "../../utils/token";

interface WalletTypes  {
    chainType:string,
    publicAddress:string
}
const Index = () => {

    const [modalContent,setModalContent] = useState(<div/>)
    const defaultWallets = localStorage.getItem('hrAddress')
    const [wallets,setWallets] = useState<WalletTypes []>(defaultWallets?JSON.parse(defaultWallets):[{chainType:'',publicAddress:''}])
    const [roleId,setRoleId] = useState('')
    const [openModal,setOpenModal] = useState(false)
    const [position,setPosition] = useState('center')
    const [hasRight,setHasRight] = useState(true)
    const [language,setLanguage] = useState('English')
    const [userInfo,setUserInfo] = useState({})
    const [initWallet,setInitWallet] = useState(false)
    const [maticRate,setMaticRate] = useState({token:'',rate:'100.11'})
    const [isBack,setIsBack] = useState(true)
    const [mailToken,setMailToken] = useState('')
    const [searchParams] = useSearchParams();
    const RoleId = searchParams.get('RoleId')
    const Language = searchParams.get('Lang')
    const Token = searchParams.get('Token')
    const ShortId = searchParams.get('ShortId')
    const Name = searchParams.get('Name')
    const localRoleId = localStorage.getItem('roleId')
    const localLanguage = localStorage.getItem('language')
    const {pathname} = useLocation()
    useEffect(() => {
        // @ts-ignore
        if (Language){
            setLanguage(Language)
        }else {
            // @ts-ignore
            setLanguage(localLanguage)
        }
        if (RoleId){
            setRoleId(RoleId)
        }else {
            // @ts-ignore
            setRoleId(localRoleId)
        }
        // @ts-ignore

        if (Token){
            setMailToken(Token)
        }else {
            setMailToken(getToken())
        }


        // @ts-ignore

    },[Token,RoleId,Language])

    useEffect(() => {
        if (RoleId){
            localStorage.setItem('roleId',RoleId)
        }
        if (Language){
            localStorage.setItem('language',Language)
        }
        if (Token){
            console.log(Token)
            setToken(Token)
        }
        if (ShortId){
            localStorage.setItem('shortId',ShortId)
        }
        if (Name){
            localStorage.setItem('Name',Name)
        }

    },[Token,RoleId,Language,ShortId,Name])
    return (
        <PContext.Provider value={{
        modalContent:modalContent,
            setModalContent:setModalContent,
            openModal:openModal,
            setOpenModal:setOpenModal,
            position:position,
            setPosition:setPosition,
            hasRight:hasRight,
            setHasRight:setHasRight,
            language:language,
            setLanguage:setLanguage,
            wallets:wallets,
            setWallets:setWallets,
            roleId:roleId,
            setRoleId:setRoleId,
            userInfo:userInfo,
            setUserInfo:setUserInfo,
            initWallet:initWallet,
            setInitWallet:setInitWallet,
            maticRate:maticRate,
            setMaticRate:setMaticRate,
            isBack:isBack,
            setIsBack:setIsBack,
        }}>
            <WModal children={modalContent}
                    position={position}
                    zIndex={999}
                    onRequestClose={() => setOpenModal(false)}
                    isOpen={openModal}/>
            {(pathname !== '/invite' && pathname !== '/follow_me') && <NavBar/>}
            {(pathname !== '/invite' && pathname !== '/follow_me') &&  <div style={{height:"2.875rem"}}/>}
            <Outlet/>
        </PContext.Provider>

    );
};

export default Index;
