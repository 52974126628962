import {useContext} from "react";
import {PContext} from "../context";

export const useModal = () => {
    // @ts-ignore
    const {openModal, setOpenModal, modalContent, setModalContent, position, setPosition, } = useContext(PContext)
    return {openModal, setOpenModal, modalContent, setModalContent, position, setPosition, }
}

export const useNavBarRight = () =>{
    // @ts-ignore
    const { hasRight, setHasRight} = useContext(PContext)
    return {hasRight, setHasRight}
}

export const useLanguage = () => {
    // @ts-ignore
    const {language,setLanguage} = useContext(PContext)
    return {language,setLanguage}
}

export const useRoleId = () => {
    // @ts-ignore
    const {roleId,setRoleId} = useContext(PContext)
    return {roleId,setRoleId}
}

export const useWallets = () => {
    // @ts-ignore
    const {wallets,setWallets} = useContext(PContext)
    return {wallets,setWallets}
}
export const useUserInfo = () => {
    // @ts-ignore
    const {userInfo,setUserInfo} = useContext(PContext)
    return {userInfo,setUserInfo}
}
export const useInitWallet = () => {
    // @ts-ignore
    const {initWallet,setInitWallet} = useContext(PContext)
    return {initWallet,setInitWallet}
}

export const useMaticRate = () => {
    // @ts-ignore
    const {maticRate,setMaticRate} = useContext(PContext)
    return {maticRate,setMaticRate}
}

export const useIsBack  = ()  => {
    // @ts-ignore
    const {isBack,setIsBack} = useContext(PContext)
    return {isBack,setIsBack}
}
