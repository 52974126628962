import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Bg1 from "../../../assets/images/withdrawal/tx_img_first_bonus_1.png"
import Bg2 from "../../../assets/images/withdrawal/tx_img_first_bonus_2.png"
import {useModal} from "../../../hooks";
import {payClient} from "../../../services/client";
const Container1 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 18.3125rem;
  height: 11.25rem;
  border-radius: 0.75rem;
`
const Container2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 18.3125rem;
  height: 18.1875rem;
`
const GiftTitle = styled.div`
  font-size: 1.25rem;
  color: #FFFFFF;
  text-shadow: 0px 1px 0px rgba(0,0,0,0.15);
  margin-top: 0.875rem;
`
const GiftText = styled.div`
  font-size: 0.625rem;
  color: #FFFFFF;
  text-shadow: 0px 1px 0px rgba(0,0,0,0.15);
  max-width: 13.125rem;
  margin-top:0.5rem;
`
const ROpen = styled.div`
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
`
const BText = styled.div`
  font-size: 1rem;
  color: #232323;
  margin-top: 1rem;
`
const Icon = styled.img`
  width: 2.75rem;
  height: 2.75rem;
  margin-top: 0.625rem;
`
const Count = styled.div`
  font-size: 0.625rem;
  color: #232323;
  margin-top: 0.5rem;
`
const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  margin-top:1rem;
`
const Btn = styled.div`
  width: 9.5625rem;
  height: 2.75rem;
  background: linear-gradient(0deg, #5C46FF, #A646FF);
  box-shadow: 0px 2px 0.625rem 0px rgba(0,0,0,0.15), 0px 1px 0px 0px #FF9999;
  border-radius: 1.3125rem;
  font-size: 1.25rem;
  color: #FFFFFF;
  text-align: center;
  line-height: 2.75rem;
  margin-top: 5rem;
  &:hover {
    filter: opacity(0.8);
  }
`
const GiftModal = ({orderNum,setHidOpen}:{orderNum:string,setHidOpen:() => void}) => {
    const [openGift,setOpenGift] = useState(0)
    const {setOpenModal} = useModal()
    const [count,setCount] = useState(0)
    useEffect(() => {
            payClient.callApi('open/QueryOrder_id',{OrderID:orderNum}).then(res => {
                if (res.isSucc){
                    // @ts-ignore
                    setCount(res.res.item.money)
                }

            }).catch(err => {

            })
    },[])
    function receive() {
        setHidOpen()
        setOpenModal(false)
    }
    return (
        <div>
            {openGift === 0 &&
            <Container1 style={{background:`url(${Bg1})`,backgroundSize:"cover"}}>
                <GiftTitle>Congratulations</GiftTitle>
                <GiftText>Congratulations on the successful withdrawal. I have presented you with the gift of a successful first withdrawal.</GiftText>
                <ROpen onClick={() => {setOpenGift(1)}}/>
            </Container1>}
            {openGift === 1 &&
            <Container2 style={{background:`url(${Bg2})`,backgroundSize:"cover"}}>
                <BText>First withdrawal of gifts</BText>
                <Icon src={require('../../../assets/images/withdrawal/tx_icon_coin_MATIC.png')}/>
                <Count>x{count}</Count>
                <GText>MATIC</GText>
                <Btn onClick={receive}>Receive</Btn>
            </Container2>}
        </div>
    );
};

export default GiftModal;
