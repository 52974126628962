import React, {CSSProperties, ReactElement} from 'react';

interface FlexBoxProps {
  children?: ReactElement | ReactElement [],
  onClick?:() => void,
  style?:CSSProperties
}

const FlexBox = ({children,onClick,style}:FlexBoxProps) => {
  return (
    <div onClick={onClick} style={{display:"flex",justifyContent:"center",alignItems:"center",...style}}>
      {children}
    </div>
  )
}
export default FlexBox
