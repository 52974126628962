import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import FlexBox from "./FlexBox";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 19.5rem;
  height: 10.375rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem;
`
const InputItem = styled.div`
  height: 2.75rem;
  width: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F3F3;
  border-radius: 0.75rem;
  font-size: 1rem;
`
const Icon = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: #000000;
  border-radius: 0.5rem;
`
const Title = styled.div`
  font-size: 1rem;
  color: #292930;
  margin-top: 1.5rem;
`
interface PasswordInputProps {
    maxLength:number,
    style?:CSSProperties,
    inputItemStyle?:CSSProperties,
    iconStyle?:CSSProperties,
    onChange:(value:string) => void,
    onSubmit?:(value:string) => void
}

const PasswordInput = ({maxLength, style, inputItemStyle, iconStyle, onChange,onSubmit}:PasswordInputProps) => {
    const [state, setState] = useState({text: ''});
    const ref = useRef<any>(null);
    useEffect(() => {
        if (state.text.length>5 && onSubmit){
            onSubmit(state.text)
        }

    },[state.text])
    const getInputItem = () => {
        let inputItem:React.ReactElement[] = [];
        for (let i = 0; i < Math.floor(maxLength); i++) {
            if (i === 0) {
                inputItem.push(
                    <InputItem key={i} style={{ ...inputItemStyle}}>
                        {i < state.text.length ? <Icon style={{ ...iconStyle}}/> : null}
                    </InputItem>);
            } else {
                inputItem.push(
                    <InputItem key={i} style={{marginLeft:"0.25rem", ...inputItemStyle}}>
                        {i < state.text.length ?
                            <Icon style={{ ...iconStyle}}/> : null}
                    </InputItem>);
            }
        }
        return inputItem;
    };
    const onPress = () => {
        ref.current.focus();
    };
    return (
            <Container onClick={onPress} style={style}>
                <Title>Please enter your password</Title>
                <FlexBox style={{justifyContent:"space-between"}}>
                    {getInputItem()}
                </FlexBox>
                <input
                    style={{height: '2.5rem', zIndex: 99, width: 45 * 6, opacity: 0}}
                    ref={ref}
                    maxLength={maxLength}
                    autoFocus={true}
                    type="text"
                    onChange={(e:any) => {
                        setState({text: e.target.value});
                        onChange(e.target.value);
                    }}
                />


            </Container>
    );

};



export default PasswordInput;
