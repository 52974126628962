import React, {useEffect} from 'react';
import styled from "styled-components";
import Bg from "../../assets/images/invite/n_yq_bg_main_1.png"
import LogBg from "../../assets/images/invite/n_yq_bg_share_photo_logo.png"
import {useWindowSize} from "../../useWindowSize";
import PhotoBg from "../../assets/images/invite/20231221-134519.png"
import BtnBg from "../../assets/images/invite/n_yq_btn_share.png"
import TipBg from "../../assets/images/invite/n_yq_bg_share_photo_notice.png"
import TitleBg from "../../assets/images/invite/n_yq_bg_title_3.png"
import FlexBox from "../../commen/FlexBox";
import {copy} from "../../utils/copy";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getAvatarImg} from "../../utils/getAvatarImg";
const Container = styled.div`
    background-image: url(${Bg});
    background-size: cover;
    width: 23.5rem;
    min-height: ${props => props.height}px;
    display: flex;
    justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
`
const LogoBg = styled.img`
    width: 21.25rem;
    height: 6.315rem;
  object-fit: cover;
  position: absolute;
  top: 0;
`
const Avatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  object-fit: cover;
  margin-top: 3rem;
`
const InvText = styled.div`
  font-size: 0.875rem;
  color: #3D746E;
  max-width: 14.44rem;
  margin-top: 1rem;
  text-align: center;
`
const CodeBox = styled.div`
  width: 8.87rem;
  height: 1.905rem;
  background: linear-gradient(0deg, #FFFFFF, #EBD3FF);
  box-shadow: 0rem 0rem 0rem 0rem rgba(0,0,0,0.15);
  border-radius: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-top: 1rem;
`
const CodeText = styled.div`
  font-size: 0.75rem;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const CopyIcon = styled.img`
  width: 1.0625rem;
  height: 1.0625rem;
  object-fit: cover;
`
const PhotoImg = styled.img`
  width: 22rem;
  height: 17.68rem;
  object-fit: cover;
  margin-top: 1.4rem;
`
const JoinBtn = styled.div`
  background-image: url(${BtnBg});
  background-size: cover;
  width: 11.72rem;
  height: 4.345rem;
  text-align: center;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: bold;
`
const TipsBox = styled.div`
  background-image: url(${TipBg});
  background-size: cover;
  width: 21.5rem;
  height: 4.78rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TipsText = styled.div`
  font-size: 1rem;
  color: #000000;
  margin-left: 1.5rem;
`
const Title = styled.div`
  background-image: url(${TitleBg});
  background-size: cover;
  width: 14.3125rem;
  height:2.906rem;
  font-size: 1rem;
  color: #FFFFFF;
  font-weight: bold;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProcessBox = styled.div`
  border: 2px dashed #FFFFFF;
  width: 21.5rem;
  height: 10.65rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  margin-top: 0.5rem;
`
const HandIcon = styled.img`
  width: 2.03rem;
  height: 3.19rem;
  object-fit: cover;
`
const ProText = styled.div`
  font-size: 0.75rem;
  color: #83838C;
  margin-top: 0.75rem;
  text-align: center;
`
const Arrow = styled.img`
  width: 1.875rem;
  height: 1rem;
  object-fit: cover;
`
const DownIcon = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: cover;
`
const SignIcon = styled.img`
  width: 1.875rem;
  height: 3.345rem;
  object-fit: cover;
`
const FzIcon = styled.img`
  height: 3.75rem;
  width: 3.75rem;
  object-fit: cover;
  border-radius: 0.75rem;
  margin-right: 0.5rem;
  margin-bottom: 0.125rem;
`
const Invite = () => {
    const {height} = useWindowSize()
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
    const [searchParams] = useSearchParams();
    const avatar = searchParams.get('avatar')
    const code = searchParams.get('code')
    const nickname = searchParams.get('nickname')
    const isLocal = searchParams.get('isLocal')
    useEffect(() => {
        if (window.ta){
            window.ta.track('landing_page_open',{invited_user_code:code});
        }
    },[window.ta])
    const Language = navigator.language
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        if (Language === 'zh-CN') {
            i18n.changeLanguage('zh')
        }else if (Language.includes('en')) {
            i18n.changeLanguage('en')
        }else if (Language === 'zh-TW') {
            i18n.changeLanguage('tc')
        }else if (Language === 'id') {
            i18n.changeLanguage('id')
        }else {
            i18n.changeLanguage('en')
        }
    },[Language])
    const toDownload = () => {
        window.ta.track('landing_page_download',{invited_user_code:code});
        if (isiOS){
            window.location.href = 'https://apps.apple.com/us/app/partyyoo/id6464553371'
        }else if (isAndroid){
            window.location.href = 'https://play.google.com/store/apps/details?id=com.luludeers.partyyoo&pli=1'
        }
    }
    return (
        <Container height={height}>
            <LogoBg src={LogBg}/>
            <Avatar src={isLocal === '1'?getAvatarImg(avatar):avatar}/>
            <InvText>{t('Your friend')}{nickname}{t('is inviting you to PartyYoo')}</InvText>
            <CodeBox>
                <CodeText>{t('Invitation code')}<div style={{margin:"0rem 0.5rem"}}>{code}</div></CodeText>
                <CopyIcon onClick={() => copy(code)} src={require('../../assets/images/invite/n_yq_icon_copy.png')}/>
            </CodeBox>
            <PhotoImg src={PhotoBg}/>
            <JoinBtn onClick={toDownload}><div style={{marginTop:"0.875rem"}}>{t('Join Now')}</div></JoinBtn>
            <TipsBox>
                <TipsText>{t('Join now to get a free set of costume')}</TipsText>
                <FzIcon src={require('../../assets/images/invite/20231207-162710.jpg')}/>
            </TipsBox>
            <Title><div style={{fontSize:"1rem"}}>{t('Accept invitation process')}</div></Title>
            <ProcessBox>
                <FlexBox style={{flexDirection:"column"}}>
                    <HandIcon src={require('../../assets/images/invite/n_yq_icon_liucheng_js_1.png')}/>
                    <ProText>{t('Click [Join Now]')}</ProText>
                </FlexBox>
                <Arrow src={require('../../assets/images/invite/n_yq_icon_liucheng_arrow.png')}/>
                <FlexBox style={{flexDirection:"column"}}>
                    <DownIcon src={require('../../assets/images/invite/n_yq_icon_liucheng_js_2.png')}/>
                    <ProText>{t('Download PartyYoo')}</ProText>
                </FlexBox>
                <Arrow src={require('../../assets/images/invite/n_yq_icon_liucheng_arrow.png')}/>
                <FlexBox style={{flexDirection:"column"}}>
                    <SignIcon src={require('../../assets/images/invite/n_yq_icon_liucheng_3.png')}/>
                    <ProText>{t('Register and enter invitation code')}</ProText>
                </FlexBox>

            </ProcessBox>
        </Container>
    );
};

export default Invite;
