import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useMaticRate, useModal, useRoleId, useUserInfo, useWallets} from "../../../hooks";
import FlexBox from "../../../commen/FlexBox";
import { utils } from 'aoas-web-sdk'
import wallet from "../../../utils/wallet";
import PasswordInput from "../../../commen/PasswordInput";
import {submitInfo} from "../../../services/submit";
import {mailClient, payClient} from "../../../services/client";
import {Toast} from "antd-mobile";
import BigNumber from "bignumber.js";
// @ts-ignore
import thinkingdata from "thinkingdata-browser";
import {useTranslation} from "react-i18next";
const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 21.43rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 1rem 1.125rem ;
`
const Cancel = styled.div`
  font-size: 0.875rem;
  color: #5C46FF;
`
const ItemTitle = styled.div`
  font-size: 0.875rem;
  color: #5C5C61;
`
const ItemText = styled.div`
  font-size: 0.875rem;
  color: #292930;
  word-break: break-all;
`
const SText = styled.div`
  font-size: 0.575rem;
  color: #292930;
`
const SIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`
const ItemBox = styled.div`
  display: flex;
  width: 21.43rem;
  align-items: center;
  justify-content:space-between;
  height:3.75rem;
  border-bottom:1px solid #EEEEEE;
`
const GText = styled.div`
  font-size: 0.5rem;
  color: #99999C;
`
const Btn = styled.div`
  width: 10.8125rem;
  height: 2.75rem;
  background: #5C46FF;
  border-radius: 0.2625rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 0.9125rem;
  color: #FFFFFF;
  margin-top: 1rem;
  &:hover {
    filter: opacity(0.8);
  }
`

const LogoIcon =styled.img`
  width: 3.375rem;
  height:1rem;
`
interface WalletTypes {
    chainType:string,
    publicAddress:string
}
interface WithProps {
    setSubmit:()=> void,
    type?:string,
    count?:string,
    callback?:(gas:number) => void,
    usCount?:number,
    toAddress?:string,
    checkIndex?:number,
    com?:number
}
const WithModalComponent = ({setSubmit,type,count,callback,usCount,toAddress,checkIndex,com}:WithProps) => {
    const {setOpenModal,setModalContent,setPosition} = useModal()
    const [gas,setGas] = useState(0)
    const [commission,setCommission] = useState(0)
    let localWallets  =localStorage.getItem('hrAddress')
    // const [wallets,setWallets] = useState(localWallets != null?localWallets[0]: {chainType:'',publicAddress:''})
    const {wallets}=useWallets()
    const [password,setPassword] = useState('')
    const {roleId} = useRoleId()
    const localRoleId = localStorage.getItem('roleId')
    const {userInfo} = useUserInfo()
    const {maticRate} = useMaticRate()
    const { t ,i18n} = useTranslation();
    let v = Number(count)
    useEffect(() => {
        const config = {
            appId: '49109fee70e64b12851d62f766e76408',
            serverUrl: 'https://global-receiver-ta.thinkingdata.cn',
            autoTrack: {
                pageShow: true, //开启页面展示事件，事件名ta_page_show
                pageHide: true, //开启页面隐藏事件，事件名ta_page_hide
            }
        };
        thinkingdata.init(config);
        // 将 SDK 实例赋给全局变量 ta，或者其他您指定的变量
        // @ts-ignore
        window.ta = thinkingdata;
        // @ts-ignore
        window.ta.init(config);
        // @ts-ignore
        // @ts-ignore
        // window.ta.track('withdraw');

    },[])
    const getPrice = async () => {
        let res = await wallet.getTokenList();
        // @ts-ignore
        let addressArr:any[] = []
        res.forEach((item:any) => addressArr.push(item.decimals))
        let decimals = addressArr[0]
        const gasL = await wallet.getGasLimit(utils.bigTrans16(v,decimals))
        const gasP = await wallet.getGasPrice()
        const gl = utils.bigDivided(gasL,decimals)
        const gp = utils.bigDivided(gasP,decimals)
        let co = parseInt(String(gl), 16) * parseInt(String(gp), 16) / Math.pow(10, 18)
        setCommission(co)
        setGas(gl * gp)
    }
    const Commission = () => {
        if (gas*100<1){
            return 1
        }else {
            return gas*100
        }
    }

    useEffect(() => {
        try {
            getPrice().then(() => {}).catch(err => {})
        } catch (error) {

        }

        if (callback){
            callback(gas)

        }

    },[])

    const onSubmit =  (password:string) => {
        Toast.show({icon:'loading',content:'Submitting',duration:10000})
        mailClient.callApi('pay/CheckPayPsw',{psw:password}).then(res => {
            if (res.isSucc && res.res.check){
                // @ts-ignore
                window.ta.track('withdraw_confirm');
                // userInfo.paypal_acc
                    submitInfo({ExtType:checkIndex===1?'paypal':'0',RoleId:roleId?roleId:localRoleId,Count:v*100,WalletAddress:checkIndex===1?userInfo.paypal_acc:wallets[0].publicAddress,Timestamp:new Date().getTime()}).then(res => {
                        if (res.data.code === 0){
                            Toast.show({icon:'success',content:t('Submit successful')})
                            setOpenModal(false)
                            // @ts-ignore
                            setSubmit()
                        }
                    }).catch(err => {
                        Toast.show({icon:'fail',content:String(err)})
                        setOpenModal(false)
                    })

            }else if (!res.res?.check){
                Toast.show({icon:'fail',content:'Password error'})
            }
        }).catch(err => {
            Toast.show({icon:'fail',content:String(err)})
        })


    }
    let orderId = toAddress + '_' +roleId+'_'+new Date().getTime()

    const createOrder =  () => {

        return payClient.callApi('open/w2w_log/CreateTask',{OrderID:orderId,RoleId:roleId?roleId:localRoleId,Wall_address:wallets[0].publicAddress,Wd_money:String(usCount?usCount:0-commission)})
    }



    const sendTransaction =  async (password:string) => {
        // @ts-ignore
        window.ta.track('withdraw_confirm');
        Toast.show({icon:'loading',content:t('Submitting...'),duration:10000})
       let r = await mailClient.callApi('pay/CheckPayPsw',{psw:password})
        if (r.isSucc && r.res.check){
            let res = await createOrder()
            if (res.isSucc){
                setOpenModal(false)
                setSubmit()
                Toast.show({icon:'success',content:t('Submit successful')})
                wallet.sendTransaction(v,toAddress?toAddress:'').then(res => {
                    payClient.callApi('open/w2w_log/PassTask',{id:orderId,callBack:res,actualReceipt:usCount?usCount:0,commission:gas})
                    setSubmit()
                }).catch(err => {
                    payClient.callApi('open/w2w_log/FaillTask',{id:orderId})

                })
            }else {
                Toast.show({icon:'fail',content:t('Submit failed')})
            }
        }else {
            Toast.show({icon:'fail',content:t('Password error')})
        }



    }

    const confirm = () => {
        setModalContent(<PasswordInput onSubmit={type === 'web3'?sendTransaction:onSubmit} onChange={(value) => {}} maxLength={6}/>)
        setPosition('center')
    }
    // @ts-ignore
    return (
        <Container >
            <FlexBox style={{justifyContent:"space-between",width:"21.43rem"}}>
                <Cancel onClick={() => {setOpenModal(false)}}>{t('Cancel')}</Cancel>
                <div/>
            </FlexBox>
            {type === 'web3' && <ItemBox>
                <ItemTitle>Currency</ItemTitle>
                <FlexBox>
                    <SIcon src={require('../../../assets/images/web3Wallet/tx_icon_coin_USDT.png')}/>
                    <ItemTitle style={{marginLeft:"0.875rem"}}>USDT</ItemTitle>
                </FlexBox>
            </ItemBox>}
            <ItemBox>
                <ItemTitle>{t('Quantity')}</ItemTitle>
                <FlexBox>
                    {type === 'web3' ?<div/> :<SIcon  src={require('../../../assets/images/balance/tx_icon_yoo_coin.png')}/>}
                    <ItemText>{type==='web3'? usCount +'USDT' :count}</ItemText>
                </FlexBox>
            </ItemBox>
            <ItemBox >
                <ItemTitle>{type === 'paypal'?"Withdrawal address":"Address"}</ItemTitle>
                {type === 'paypal'?<FlexBox style={{justifyContent:"flex-end",width: '20.625rem'}}>
                        <LogoIcon src={require('../../../assets/images/withdrawal/tx_icon_paypal.png')}/>
                        <ItemText style={{marginLeft:"0.5rem"}}>{userInfo.paypal_acc}</ItemText>
                </FlexBox>:

                 <SText>{type ==='web3' ? toAddress:wallets[0].publicAddress}</SText>}
            </ItemBox>
            {type !== 'paypal' &&
            <ItemBox>
                <ItemTitle>Transfer network</ItemTitle>
                <ItemText>Polygon</ItemText>
            </ItemBox>}
            {type !== 'paypal' &&
            <ItemBox>
                <div>
                    <ItemTitle>Commission</ItemTitle>
                    <GText>Commission fluctuates based on gas fees</GText>
                </div>

                <FlexBox>
                    {type === 'web3' ?<div/> :<SIcon  src={require('../../../assets/images/balance/tx_icon_yoo_coin.png')}/>}
                    <ItemText>{type==='web3'?`${BigNumber((com?com:1)).toFixed()}  MATIC`:Commission()}</ItemText>
                </FlexBox>
            </ItemBox>}
            <ItemBox>
                <ItemTitle>Expected receipt</ItemTitle>
                <ItemText>{type==='web3'?(usCount?usCount:0)-commission:String(v/100-gas).substring(0,5)} USDT</ItemText>
            </ItemBox>
            <Btn onClick={confirm}>{t('Confirm')}</Btn>
        </Container>
    );
};

export default WithModalComponent;
