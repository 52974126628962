import axios from 'axios'
import {BaseUrl} from "./baseUrl";
const baseURL = BaseUrl +':8001'
// http://192.168.33.6:8001
const fbrequest = axios.create({
    baseURL:baseURL,//基准地址
    timeout:5000,
})
//拦截请求
fbrequest.interceptors.request.use((config)=>{
    config.headers['Origin'] = BaseUrl +':8001'
    return config
})
//拦截响应
fbrequest.interceptors.response.use((response)=>{
        return response
    },function (error){
        //对响应的错误做点什么
        return Promise.reject(error);
    }
)

export default fbrequest
