import request from "../utils/request";
// http://118.178.187.93:5003/ticket/balance?RoleId=1894189755604729859
interface balance {
    RoleId:string | null,
    TimeStamp?:number,
    PageSize?:number,
    PageNumber?:number,
    Language?:string| null
}
export const getGiftsList= (params:balance) => {
    return request('/ticket/gift_value_log',
        {
            method:'GET',
            params
        })
}
