import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Form, ImageUploader, TextArea, Toast} from "antd-mobile";
import {useWindowSize} from "../../useWindowSize";
import {createFeedback, getAmazonPutUrl} from "../../services/feedback";

import axios from "axios";
import FlexBox from "../../commen/FlexBox";
import {getToken} from "../../utils/token";
import md5 from "js-md5";
import {useTranslation} from "react-i18next";

const Container = styled.div`
    margin: 0 1rem;
   background-color: #FFFFFF;
  padding-bottom: 2rem;
`
const CreateBtn = styled.div`
    background-color: #5847F5;
    height: 2.75rem;
    width: 17rem;
    color: #ffffff;
    font-size: 1rem;
  line-height: 2.75rem;
  text-align: center;
  border-radius:0.75rem;
  
`
const AddImgContainer = styled.div`
  border-radius:0.75rem;
  border:1px solid #EAEAEA;
  width:21.25rem;
  background-color:#F8F8F9;
`
const TipsText = styled.div`
  padding: 0.5rem 0;
  margin: 0 1rem;
  font-size: 1rem;
  color: #B2B5C1;
  border-bottom: 1px solid #E3E3E3;
`
const Feedback = () => {
    const [content,setContent] = useState('')
    const [fileList,setFileList] = useState([])
    const {height} = useWindowSize()
    const RoleId = localStorage.getItem('roleId')
    const ShortId = localStorage.getItem('shortId')
    const Name = localStorage.getItem('Name')
    const Language = localStorage.getItem('language')
    const Token = getToken()
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        if (Language === '3') {
            i18n.changeLanguage('zh')
        }else if (Language === '1') {
            i18n.changeLanguage('en')
        }else if (Language === '4') {
            i18n.changeLanguage('tc')
        }else if (Language === '0') {
            i18n.changeLanguage('id')
        }else {
            i18n.changeLanguage('en')
        }
    },[Language])
    useEffect(() => {

        let formLine = document.getElementsByClassName('adm-list-item-content')
        let listBody = document.getElementsByClassName('adm-list-body')
        listBody[0].style.border ='none'
        formLine[0].style.border = 'none'
        formLine[1].style.border = 'none'

    },[])
    const textRef = useRef(null)
    const putImg = async (file) => {
        const exTension = file.type.split('/')[1]
        const time = new Date().getTime()
        const sign = RoleId + Token + time
        // Toast.show({icon:'loading',content:t('Uploading'),duration:0})
        let response = await getAmazonPutUrl({PutRoleId:RoleId,RoleName:Name,Extension:exTension,RId:RoleId,Token:Token,TimeStamp:time,Sign:md5(sign),})

        return new Promise( async (resolve, reject) => {
            if (response.data.code === 1003 || response.data.code === 1001 ||response.data.code !== 0) {
                reject({ url: 'The link has expired',state:403})
                return  Toast.show({icon:'fail',content:t('The link has expired, please try entering again')})
            }
            if(response.data.code === 0){
                const headers = new Headers();
                headers.append('Content-Type', 'multipart/form-data'); // 设置文件的 Content-Type
                axios.put(response.data.data, file,{
                    headers: headers,
                }).then(res => {
                    if (res.status === 200) {
                        const url = 'https://images.partyyoo.com/feedback/'+ response.data.msg
                        resolve({url:url,state:200})
                        Toast.show({icon:'success',content:t('Upload Successfully')})
                    }else {
                        reject({ url: '',state:403})
                        Toast.show({icon:'fail',content:t('Upload failed, there seems to be a problem with the request')})
                    }

                }).catch(err => {
                    reject({ url: '' +err,state:403})
                    Toast.show({icon:'fail',content:t('Upload failed')})
                })


            }else {
                reject({ url: '',state:403})
                Toast.show({icon:'fail',content:t('Upload failed')})
            }
        })
    }
    const create = () => {
        if (content === ''){
            return Toast.show({icon:'fail',content:t('Content cannot be empty')})
        }
        const urlArr = fileList.map(item => item.url)
        const time = new Date().getTime()
        const sign = RoleId + Token + time
        setContent('')
        createFeedback({RId:RoleId,RoleName:Name,Content:content,Token:Token,ShortId:ShortId,ImageUrls:urlArr.join(','),Sign:md5(sign),TimeStamp:time}).then(res => {
            if (res.data.code === 0) {
                setContent('')
                textRef.current.clear()
                return Toast.show({icon:'success',content:t('Successfully submitted, waiting for a reply')})
            }else {
                return Toast.show({icon:'fail',content:t('Submission failed')})
            }
        }).catch(err => {})
    }
    console.log(fileList)
    return (
        <Container style={{height:height,}}>
            <Form style={{borderRadius:"0.5rem",width:"100%"}} requiredMarkStyle='text-required' layout='vertical'  >
                <Form.Item name='topic' style={{paddingLeft:"0"}}>
                    <TextArea
                        style={{"--font-size":"1rem",
                            '--color':"#000000",
                            borderRadius:'0.75rem',
                            border:"1px solid #EAEAEA",
                            width:"19.25rem",
                            padding:"1rem",
                            backgroundColor:'#F8F8F9'
                        }}
                        ref={textRef}
                        placeholder={t('Please leave your feedback and we will try our best to improve your experience in PartyYoo')}
                        value={content}
                        maxLength={500}
                        showCount={true}
                        onChange={val => {
                            setContent(val)
                        }}
                        autoSize={{ minRows: 5, maxRows: 10 }}
                    />
                </Form.Item>
                <AddImgContainer>
                    <TipsText>{t('Please provide photos or screenshots of the problem you reported')} ({fileList.length}/3)</TipsText>
                    <Form.Item style={{background: "#F8F8F9",}} name={'url'}>
                            <ImageUploader
                                value={fileList}
                                maxCount={3}
                                onChange={setFileList}
                                showFailed={false}
                                onDelete={(file) => {
                                    let newImg = fileList.filter(item => item.url !== file.url)
                                    setFileList(newImg)}}
                                upload={(file) => putImg(file)}
                                renderItem={(originNode, file, list) => {
                                    return (
                                        <div key={file}>
                                            {originNode}
                                        </div>
                                    );
                                }}
                            >
                                <FlexBox
                                    style={{
                                        width: "5rem",
                                        height: "5rem",
                                        background: "#E8E8E8",
                                        borderRadius: "0.75rem"
                                    }}>
                                    <img style={{width: "2.04rem", height: "2.04rem"}}
                                         src={require('../../assets/images/feedback/add_img_icon.png')} alt=''/>
                                </FlexBox>
                            </ImageUploader>

                    </Form.Item>

                </AddImgContainer>

            </Form>
            <FlexBox style={{width:"21.5rem",margin:"1rem 0",paddingBottom:"2rem"}}>
                <CreateBtn onClick={create}>{t('Submit')}</CreateBtn>
            </FlexBox>

        </Container>
    );
};

export default Feedback;
