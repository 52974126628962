import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import CountDown from "../../commen/CountDown";
import FlexBox from "../../commen/FlexBox";
import {isEmail} from "../../utils";
import SuccessIcon from "../../assets/images/withdrawal/tx_order_state_success.png";
import {useModal, useNavBarRight, useUserInfo} from "../../hooks";
import {mailClient} from "../../services/client";
import {Toast} from "antd-mobile";
import CheckInstitutionModal from "./components/checkInstitutionModal";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #F4F4F8;
  
`
const Title = styled.div`
  font-size: 1rem;
  color: #292930;
  width: 21.5rem;
  margin-top: 1rem;
`
const WBox = styled.div`
  width: 20.125rem;
  height: 2.0625rem;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 0.652rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 0.5rem;
`

const GText = styled.div`
  font-size: 0.625rem;
  color: #99999C;
  margin-top:1rem;
`
const Input = styled.input`
  width: 10rem;
  height: 2rem;
  outline: none;
  border: none;
  color: #232323;
  font-size: 1rem;
  
`
const Btn = styled.div`
  width: 14.9375rem;
  height: 2.75rem;
  background: ${props => props.color};
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #FFFFFF;
  line-height: 2.75rem;
  text-align: center;
  position: fixed;
  bottom: 1.5rem;
  left:4.075rem
`
const ErrText = styled.div`
  font-size: 0.625rem;
  color: #000000;
  margin-left: 1rem;
  margin-top: 0.25rem;
  width: 21.5rem;
`
const StatusIcon = styled.img`
  width: 3.25rem;
  height: 3.25rem;
  margin-top: 2rem;
`
const RightArrowIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.35rem;
`

const AddCollectionAccount = () => {
    const {userInfo} = useUserInfo()
    const [name,setName] = useState('')
    const [institution,setInstitution] = useState('')
    const [account,setAccount] = useState('')
    const [email,setEmail] = useState('')
    const [canSubmit,setCanSubmit] = useState(false)
    const {setHasRight} = useNavBarRight()
    const {setUserInfo} = useUserInfo()
    const location = useLocation()
    const navigate = useNavigate()
    const {wType} = location.state
    const {setModalContent,setOpenModal,setPosition} = useModal()
    const { t ,i18n} = useTranslation();
    useEffect(() => {
        setHasRight(false)
    },[])
    useEffect(() => {
        if (account && name && institution) {
            setCanSubmit(true);
        }
    },[account,name,institution])
    function submit() {
        if (!account || !name || !institution) {
            return setCanSubmit(false);
        }
        setCanSubmit(true)
        mailClient.callApi('financial/BindFinancialAccount',{
            fAccount:{
                account_type:wType===0?'BANK_ACCOUNT':'E_WALLET',
                account_number:account,
                bank_name:institution,
                payment_type:wType===0?'BANK_TRANSFER':'WALLET',
                institution_target:institution,
                full_name:name,
                email:email
            }
        }).then((res)=>{
            if (res.isSucc){
                navigate(-1)
                Toast.show({
                    icon:'success',
                    content:'添加成功'
                })
                // setUserInfo({...userInfo,financialAccount:res.data.data})
            }else {
                Toast.show({
                    icon:'fail',
                    content:'添加失败'
                })
            }


        })
    }

    return (
        <div>
            <Container>
                <Title>{t('Recipient Name')}</Title>
                <WBox>
                    <Input value={name} onChange={(e) => setName(e.target.value)} type={'text'} placeholder={t('Please enter full name')} />
                </WBox>
                <Title>{wType===0?t('Recipient Institution'):t('Recipient Wallet')}</Title>
                <WBox onClick={() => {setModalContent(<CheckInstitutionModal callback={(ins) => {setInstitution(ins)}}/>);setPosition('bottom');setOpenModal(true)}}>
                    <div style={{fontSize:"1rem",color:"#B6B6B6"}}>{institution ? institution:`${wType===0?t('Select the recipient institution'):t('Select Recipient Wallet')}`}</div>
                    <RightArrowIcon src={require('../../assets/images/balance/tx_icon_list_arrow.png')}/>
                </WBox>
                <Title>{t('Recipient Account')}</Title>
                <WBox>
                    <Input value={account} onChange={(e) => setAccount(e.target.value)} type={'text'} placeholder={t('Please enter')} />
                </WBox>
                <ErrText>{t('The recipient account number only contains numbers and letters')}</ErrText>
                <Title>{t('Notification Email')+t('(optional)')}</Title>
                <WBox>
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} type={'text'} placeholder={t('Please enter')} />
                </WBox>
                <Btn color={canSubmit?"#5C46FF":"#E2E5E9"} onClick={submit}>{t('Confirm')}</Btn>
            </Container>
        </div>
    );
};

export default AddCollectionAccount;
